// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type AvailableResourcesQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["Int"]["input"];
  services:
    | Array<Types.Scalars["Int"]["input"]>
    | Types.Scalars["Int"]["input"];
  visitStart: Types.Scalars["DateTime"]["input"];
  visitEnd: Types.Scalars["DateTime"]["input"];
  visit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type AvailableResourcesQuery = {
  __typename?: "query_root";
  availableResources?: {
    __typename?: "AvailableResourcesType";
    medspaRooms?: {
      __typename?: "RoomType";
      availableRooms?: number | null;
      isRoomRequired?: boolean | null;
    } | null;
    serviceDevices?: Array<{
      __typename?: "ServiceDeviceType";
      serviceTypeName: string;
      serviceTypeId: number;
      availableDevices: number;
    } | null> | null;
  } | null;
};

export const AvailableResourcesDocument = gql`
  query AvailableResources(
    $medspaId: Int!
    $services: [Int!]!
    $visitStart: DateTime!
    $visitEnd: DateTime!
    $visit: Int
  ) {
    availableResources(
      medspaId: $medspaId
      serviceMenuItemIds: $services
      visitStart: $visitStart
      visitEnd: $visitEnd
      visit: $visit
    ) {
      medspaRooms {
        availableRooms
        isRoomRequired
      }
      serviceDevices {
        serviceTypeName
        serviceTypeId
        availableDevices
      }
    }
  }
`;

/**
 * __useAvailableResourcesQuery__
 *
 * To run a query within a React component, call `useAvailableResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableResourcesQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *      services: // value for 'services'
 *      visitStart: // value for 'visitStart'
 *      visitEnd: // value for 'visitEnd'
 *      visit: // value for 'visit'
 *   },
 * });
 */
export function useAvailableResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailableResourcesQuery,
    AvailableResourcesQueryVariables
  > &
    (
      | { variables: AvailableResourcesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableResourcesQuery,
    AvailableResourcesQueryVariables
  >(AvailableResourcesDocument, options);
}
export function useAvailableResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableResourcesQuery,
    AvailableResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableResourcesQuery,
    AvailableResourcesQueryVariables
  >(AvailableResourcesDocument, options);
}
export function useAvailableResourcesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AvailableResourcesQuery,
        AvailableResourcesQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AvailableResourcesQuery,
    AvailableResourcesQueryVariables
  >(AvailableResourcesDocument, options);
}
export type AvailableResourcesQueryHookResult = ReturnType<
  typeof useAvailableResourcesQuery
>;
export type AvailableResourcesLazyQueryHookResult = ReturnType<
  typeof useAvailableResourcesLazyQuery
>;
export type AvailableResourcesSuspenseQueryHookResult = ReturnType<
  typeof useAvailableResourcesSuspenseQuery
>;
export type AvailableResourcesQueryResult = Apollo.QueryResult<
  AvailableResourcesQuery,
  AvailableResourcesQueryVariables
>;

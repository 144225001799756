import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import toast from "react-hot-toast";
import { FeaturePermission } from "@/__generated__/featurePermissions";
import { hasRole, useUser } from "@/auth/useUser";
import { DropdownMenuItem } from "@/components/common/dropdownMenu/dropdownMenu";
import { CherryApplicationStatus } from "@/components/common/paymentPlans/cherryStatus";
import { DropdownAction } from "@/components/dropdown/dropdown";
import { DATE_FORMATS } from "@/config";
import { TEXT_SECONDARY } from "@/config/mui/colorPalette";
import { useSendCherryLinkToClientMutation } from "@/graphql/mutations/paymentPlans/sendCherryLinkToClient.graphql.types";
import { useUpdateClientPaymentPlansInterestMutation } from "@/graphql/mutations/paymentPlans/updateClientPaymentPlansInterest.graphql.types";
import useMedspaTimezone from "@/hooks/common/useMedspaTimezone";
import { FRONT_DESK, PROVIDER } from "@/types";
import useErrorLogger from "@/utils/useErrorLogger";

export type ClientData = {
  id: string;
  interestedInPaymentPlans?: boolean;
  cherryApplicationLinkLastSentAt?: string;
};

function usePaymentPlansActions(
  client: ClientData,
  status: CherryApplicationStatus,
  appointmentId: string
) {
  const logError = useErrorLogger();

  const [sendCherryLinkMutation] = useSendCherryLinkToClientMutation();
  const [updateClientInterestMutation] =
    useUpdateClientPaymentPlansInterestMutation();

  const { user, newPermissionsEnabledForUser, hasFeaturePermission } =
    useUser();

  const sendCherryLink = async () => {
    const { data } = await sendCherryLinkMutation({
      variables: { appointmentId },
      update(cache) {
        cache.modify({
          id: `client:${client.id}`,
          fields: {
            cherryApplicationLinkLastSentAt() {
              return new Date().toISOString();
            },
          },
        });
      },
    });

    if (!data?.sendCherryApplicationLinkToClient) {
      throw new Error("Failed to send Cherry application link");
    }
    const { ok, message } = data.sendCherryApplicationLinkToClient;
    if (!ok) throw new Error(message);
  };

  const handleSendLink = async () => {
    try {
      await toast.promise(sendCherryLink(), {
        loading: "Sending link...",
        success: "Link sent!",
        error: "Failed to send link",
      });
    } catch (errors) {
      logError(errors);
    }
  };

  const interestedInPaymentPlans = client?.interestedInPaymentPlans !== false;
  const linkLastSentAt = client?.cherryApplicationLinkLastSentAt;

  const handleUpdateClientInterest = async () => {
    if (!client) throw new Error("Client information missing");

    try {
      await toast.promise(
        updateClientInterestMutation({
          variables: {
            clientId: client.id,
            interestedInPaymentPlans: !interestedInPaymentPlans,
          },
        }),
        interestedInPaymentPlans
          ? {
              loading: "Dismissing payment plan interest...",
              success: "Payment plan interest dismissed",
              error: "Failed to dismiss payment plan interest",
            }
          : {
              loading: "Marking client as interested...",
              success: "Client marked as interested!",
              error: "Failed to mark client as interested",
            }
      );
    } catch (errors) {
      logError(errors);
    }
  };

  const canSendCherryLink = newPermissionsEnabledForUser
    ? hasFeaturePermission(FeaturePermission.SEND_CHERRY_LINK_TO_CLIENT)
    : hasRole(user, [PROVIDER, FRONT_DESK]);

  const canUpdateClientInterest =
    hasRole(user, [PROVIDER, FRONT_DESK]) &&
    (status == CherryApplicationStatus.NO_RECORD || !interestedInPaymentPlans);

  const cherryLinkActionTitle = linkLastSentAt
    ? "Resend Cherry application link via SMS"
    : "Send Cherry application link via SMS";

  const clientInterestActionTitle = interestedInPaymentPlans
    ? "Dismiss payment plan interest"
    : "Mark interested in payment plans";

  return {
    handleSendLink,
    handleUpdateClientInterest,
    cherryLinkActionTitle,
    clientInterestActionTitle,
    canSendCherryLink,
    canUpdateClientInterest,
  };
}

export function usePaymentPlansDropdownActions(
  client: ClientData,
  status: CherryApplicationStatus,
  appointmentId: string
): DropdownAction[] {
  const {
    canSendCherryLink,
    cherryLinkActionTitle,
    canUpdateClientInterest,
    clientInterestActionTitle,
    handleSendLink,
    handleUpdateClientInterest,
  } = usePaymentPlansActions(client, status, appointmentId);

  const paymentPlansActions: DropdownAction[] = [
    canSendCherryLink && {
      title: cherryLinkActionTitle,
      action: handleSendLink,
    },
    canUpdateClientInterest && {
      title: clientInterestActionTitle,
      action: handleUpdateClientInterest,
    },
  ].filter(Boolean);

  return paymentPlansActions;
}

export function usePaymentPlansDropdownMenuItems(
  client: ClientData,
  status: CherryApplicationStatus,
  appointmentId: string
): DropdownMenuItem[] {
  const { utcToMedspaZonedTime } = useMedspaTimezone();
  const {
    canSendCherryLink,
    canUpdateClientInterest,
    cherryLinkActionTitle,
    clientInterestActionTitle,
    handleSendLink,
    handleUpdateClientInterest,
  } = usePaymentPlansActions(client, status, appointmentId);

  const linkLastSentAt = client?.cherryApplicationLinkLastSentAt;
  const linkLastSentAtText = linkLastSentAt
    ? `Link last sent ${format(utcToMedspaZonedTime(linkLastSentAt), DATE_FORMATS.DATE_PICKER)}`
    : "Link unsent";

  const paymentPlansActions: DropdownMenuItem[] = [
    {
      component: (
        <Box>
          <Typography variant="paragraphSmall">
            {cherryLinkActionTitle}
          </Typography>
          <Typography variant="paragraphTiny" color={TEXT_SECONDARY}>
            {linkLastSentAtText}
          </Typography>
        </Box>
      ),
      onClick: handleSendLink,
      disabled: !canSendCherryLink,
      divider: canUpdateClientInterest ? false : true,
    },
    {
      component: clientInterestActionTitle,
      onClick: handleUpdateClientInterest,
      disabled: !canUpdateClientInterest,
      divider: true,
    },
  ];

  return paymentPlansActions;
}

import { AuthUser } from "@/auth/useUser";
import {
  HASURA_IMPERSONATED_ID,
  HASURA_IMPERSONATOR_ID,
  HasuraClaimsType,
} from "@/types";
import { setDatadogUser } from "@/utils/datadog";

export default function trackUserSessionReplay(
  user: AuthUser,
  hasuraClaims: HasuraClaimsType
) {
  const isImpersonation = !!hasuraClaims[HASURA_IMPERSONATED_ID];
  const sanitizedEmail =
    user.role === "client" ? "PATIENT_EMAIL_REDACTED" : user.email;
  const impersonationData = isImpersonation
    ? {
        impersonated_id: hasuraClaims[HASURA_IMPERSONATED_ID],
        impersonator_id: hasuraClaims[HASURA_IMPERSONATOR_ID],
      }
    : null;

  setDatadogUser({
    id: user.id,
    email: sanitizedEmail,
    role: user.role,
    is_impersonation_session: isImpersonation,
    ...impersonationData,
  });

  if (typeof window === "undefined") return;
}

import { useEffect } from "react";
import { buildClientTokenContext } from "@/contexts/clientAccessTokenContext";
import { useClientTrackingByTokenQuery } from "@/graphql/queries/clientTrackingByToken.graphql.types";
import { clearDatadogUser, setDatadogUser } from "@/utils/datadog";

export const useDatadogTrackClientByAccessToken = (
  clientAccessToken: string,
  isClientPage: boolean
) => {
  const { data } = useClientTrackingByTokenQuery({
    variables: {
      token: clientAccessToken,
    },
    skip: !isClientPage || !clientAccessToken,
    ...buildClientTokenContext(clientAccessToken),
  });

  useDatadogTrackClient(data?.clientAccessTokenByPk?.client, isClientPage);
};

export const useDatadogTrackClient = (
  client: { id?: string; email: string } | undefined,
  isClientPage = true
) =>
  useEffect(() => {
    if (!isClientPage) {
      return;
    }

    if (client && (client.id || client.email)) {
      if (client.id) {
        setDatadogUser({
          id: client.id,
          email: "PATIENT_EMAIL_REDACTED",
          role: "client",
        });
      } else {
        setDatadogUser({
          email: client.email,
          role: "client",
        });
      }
    } else {
      clearDatadogUser();
    }
  }, [client, isClientPage]);

import { Divider, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import SuccessCircleIconSvg from "@/components/common/icons/successCircleIconSvg";
import { DATE_FORMATS, TIME_FORMATS } from "@/config";
import { GREY } from "@/config/mui/colorPalette";
import {
  ClientForVisitCreation,
  Provider,
} from "@/hooks/visits/useCreateVisit";
import { ServiceWithCategory } from "./createVisitDrawerForm";

export const CreateVisitDrawerSuccess = ({
  client,
  provider,
  start,
  end,
  selectedServices,
}: {
  client: ClientForVisitCreation;
  provider: Provider;
  start: Date;
  end: Date;
  selectedServices: ServiceWithCategory[];
}) => {
  return (
    <>
      <Stack
        direction="column"
        sx={{
          alignItems: "center",
          width: "100%",
          backgroundColor: GREY[5],
          py: 2,
          px: 1.5,
          borderRadius: 2,
        }}
      >
        <SuccessCircleIconSvg size="48px" />
        <Typography variant="labelDefault">Success!</Typography>
        <Typography variant="h3">Appointment has been booked</Typography>
        <Divider
          orientation="horizontal"
          variant="fullWidth"
          flexItem
          sx={{ my: 2 }}
        />
        <Stack
          direction="row"
          sx={{
            gap: 2,
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="paragraphSmall"
            sx={{
              color: "text.secondary",
            }}
          >
            Client
          </Typography>
          <Typography variant="labelSmall">
            {client?.firstName} {client?.lastName ?? ""}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          sx={{
            gap: 2,
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="paragraphSmall"
            sx={{
              color: "text.secondary",
            }}
          >
            Provider
          </Typography>
          <Typography variant="labelSmall">
            {provider?.user.fullName}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          sx={{
            gap: 2,
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="paragraphSmall"
            sx={{
              color: "text.secondary",
            }}
          >
            Date & Time
          </Typography>
          <Typography variant="labelSmall">
            {`${format(start, DATE_FORMATS.DATE_PICKER)}, ${format(start, TIME_FORMATS.TILE)} - ${format(end, TIME_FORMATS.TILE)}`}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          sx={{
            gap: 2,
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="paragraphSmall"
            sx={{
              color: "text.secondary",
            }}
          >
            Service(s)
          </Typography>
          <Stack
            direction="column"
            sx={{
              gap: 1,
            }}
          >
            {selectedServices?.map((service) => (
              <Typography key={service.id} variant="labelSmall">
                {service.name}
              </Typography>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

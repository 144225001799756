import { useEffect, useState } from "react";

type ExpandedState = {
  [id: string]: boolean;
};

const getInitialExpandedState = <T extends { id: string }>(
  items: T[],
  defaultExpanded = true
): ExpandedState => {
  return items.reduce(
    (acc, item) => ({ ...acc, [item.id]: defaultExpanded }),
    {}
  );
};

export const useExpandedState = <T extends { id: string }>(
  items: T[],
  defaultExpanded?: boolean
) => {
  const [itemExpanded, setItemExpanded] = useState<ExpandedState>(
    getInitialExpandedState(items, defaultExpanded)
  );

  useEffect(() => {
    setItemExpanded((prevVal) => {
      const updates = items.reduce((acc, item) => {
        if (!(item.id in prevVal)) {
          acc[item.id] = defaultExpanded;
        }
        return acc;
      }, {});
      return { ...prevVal, ...updates };
    });
  }, [items, defaultExpanded]);

  const handleItemExpand = (id: string) => {
    setItemExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return { itemExpanded, onItemExpand: handleItemExpand };
};

import { Box, SxProps, Theme } from "@mui/material";
import { omit } from "lodash";
import dynamic from "next/dynamic";
import { QuillOptions } from "quill";
import "quill/dist/quill.snow.css";
import { TEXT_SECONDARY } from "@/config/mui/colorPalette";

const QuillWrapperNoSSRWrapper = dynamic(
  () => import("@/components/common/quill/quillWrapper"),
  {
    ssr: false,
    loading: () => <div>Loading editor...</div>,
  }
);

// even if the quill input is empty, the getLength (https://quilljs.com/docs/api/#getlength) will still return empty line
export const hasText = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const textNodes = doc.body.querySelectorAll("*");

  for (const node of textNodes) {
    if (node.textContent.trim() !== "") {
      return true;
    }
  }

  return false;
};

export type QuillProps = QuillOptions & {
  value?: string;
  disableToolbar?: boolean;
  sx?: SxProps<Theme>;
  onChange?: (value: string) => void;
  afterSetValue?: () => void;
};

export const EDITOR_CLASS = "& .ql-editor";

export default function Quill({
  value,
  onChange,
  disableToolbar = false,
  readOnly,
  sx,
  ...props
}: QuillProps) {
  const styles = {
    [EDITOR_CLASS]: {
      ...(readOnly
        ? {
            padding: 0,
            lineHeight: "24px",
            color: TEXT_SECONDARY,
          }
        : {}),
      // Merge any custom editor styles
      ...sx?.[EDITOR_CLASS],
    },
    // Merge remaining custom styles
    ...omit(sx || {}, EDITOR_CLASS),
  };

  return (
    <Box sx={styles}>
      <QuillWrapperNoSSRWrapper
        value={value}
        disableToolbar={disableToolbar}
        onChange={onChange}
        readOnly={readOnly}
        {...props}
      />
    </Box>
  );
}

import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

export const setDatadogUser = (user: {
  id?: string;
  email: string;
  role: string;
  is_impersonation_session?: boolean;
  impersonated_id?: string;
  impersonator_id?: string;
}) => {
  datadogLogs.setUser(user);
  datadogRum.setUser(user);
};

export const clearDatadogUser = () => {
  datadogLogs.clearUser();
  datadogRum.clearUser();
};

import { Button, Collapse, ListSubheader } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { useCallback, useMemo, useState } from "react";
import { useUser } from "@/auth/useUser";
import {
  GREY,
  TEXT_PRIMARY_DARK,
  TEXT_SECONDARY,
  TEXT_TERTIARY,
  VIOLET,
  WHITE,
} from "@/config/mui/colorPalette";
import NextLink from "@/config/mui/nextLink";
import useFeatureFlags from "@/hooks/common/useFeatureFlags";
import { useResponsive } from "@/hooks/common/useResponsive";
import useIsPWA from "@/hooks/misc/useIsPWA";
import { useSelectiveEffect } from "@/hooks/misc/useSelectiveEffect";
import useNavigationData, {
  NavDataType,
} from "@/hooks/navigation/useNavigationData";
import { getDefaultRoute } from "@/utils";
import { NewScreenIcon } from "../icons";
import ReferralCard from "../referralCard";
import { IMPERSONATION_BAR_HEIGHT } from "../topBar/impersonationBar";
import { MAINTENANCE_BANNER_HEIGHT } from "../topBar/maintenanceBanner";
import { TOP_BAR_HEIGHT } from "../topBar/topBar";

export const NAV_WIDTH = "280px";

type VerticalNavProps = {
  openNav: boolean;
  onCloseNav: () => void;
};

export default function VerticalNav({ openNav, onCloseNav }: VerticalNavProps) {
  const pathname = usePathname();
  const { user, medspa } = useUser();
  const { navData } = useNavigationData(user?.role, onCloseNav);
  const mdUp = useResponsive("up", "md");

  const isImpersonating = !!user?.impersonatedId;
  const { isMaintenanceScheduled } = useFeatureFlags();

  // Calculates the `menuPaddingTop` as a CSS `calc()` string,
  // accounting for `TOP_BAR_HEIGHT`, `IMPERSONATION_BAR_HEIGHT` if impersonating,
  // and `MAINTENANCE_BANNER_HEIGHT` if maintenance is scheduled.
  const menuPaddingTop = `calc(${TOP_BAR_HEIGHT} + ${
    isImpersonating ? IMPERSONATION_BAR_HEIGHT : "0px"
  } + ${isMaintenanceScheduled ? MAINTENANCE_BANNER_HEIGHT : "0px"})`;

  useSelectiveEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const content = useMemo(
    () => (
      <Stack sx={{ flexDirection: "column", m: 2, flex: 1 }}>
        {mdUp && (
          <Box
            component={NextLink}
            href={getDefaultRoute({ medspa: { id: medspa }, role: user?.role })}
            sx={{
              px: 2,
              py: 1,
            }}
          >
            <Image
              src="/assets/moxie-logo.svg"
              width={80}
              height={20}
              alt="Moxie logo"
            />
          </Box>
        )}
        <NavSectionVertical navData={navData} />
      </Stack>
    ),
    [mdUp, medspa, user?.role, navData]
  );

  return (
    <Box
      sx={{
        flexShrink: { md: 0 },
        width: { md: 280 },
      }}
    >
      {mdUp ? (
        <Stack
          sx={{
            height: "100%",
            overflowY: "auto",
            position: "fixed",
            width: NAV_WIDTH,
            backgroundColor: WHITE,
            borderRight: `1px solid ${GREY[20]}`,
          }}
        >
          {content}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              paddingTop: menuPaddingTop,
              width: NAV_WIDTH,
              overscrollBehavior: "contain",
            },
          }}
        >
          {content}
        </Drawer>
      )}
    </Box>
  );
}

function NavSectionVertical({ navData }: { navData: NavDataType }) {
  const { referAndEarnV1Enabled } = useFeatureFlags();
  const isPWA = useIsPWA();

  return (
    <Stack
      component="nav"
      sx={{
        py: 2,
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: isPWA ? `calc(100vh - ${TOP_BAR_HEIGHT})` : undefined,
      }}
    >
      {navData.map((group, index) => (
        <Group
          key={group.subheader || index}
          subheader={group.subheader}
          items={group.items}
        />
      ))}
      {referAndEarnV1Enabled && (
        <Box sx={{ pt: 4 }}>
          <ReferralCard />
        </Box>
      )}
    </Stack>
  );
}

function Group({
  subheader,
  items,
}: {
  subheader?: string;
  items?: NavDataType[number]["items"];
}) {
  const [open, setOpen] = useState(true);
  const path = usePathname();

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const navButtons = useMemo(() => {
    return items?.map((list) => {
      const active = list.getIsActive
        ? list.getIsActive(path)
        : path.includes(list.path);

      const NotificationComponent = list.notifications;

      return (
        <Button
          fullWidth
          key={list.title}
          href={list.path}
          onClick={list.onClick}
          startIcon={
            <list.icon size={24} color={active ? VIOLET[90] : GREY[50]} />
          }
          endIcon={
            list.isExternal && <NewScreenIcon size={16} color={GREY[50]} />
          }
          sx={{
            backgroundColor: active ? VIOLET[20] : WHITE,
            pl: 1.5,
            pr: 2,
            py: 1,
            fontSize: 14,
            fontWeight: active ? 600 : 400,
            color: active ? VIOLET[90] : TEXT_SECONDARY,
            justifyContent: "flex-start",
          }}
        >
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box>{list.title}</Box>
            {NotificationComponent && <NotificationComponent />}
          </Stack>
        </Button>
      );
    });
  }, [items, path]);

  return (
    <Stack>
      {subheader ? (
        <>
          <ListSubheader
            disableGutters
            disableSticky
            onClick={handleToggle}
            sx={{
              fontSize: 12,
              cursor: "pointer",
              color: TEXT_TERTIARY,
              "&:hover": {
                color: TEXT_PRIMARY_DARK,
              },
            }}
          >
            {subheader}
          </ListSubheader>

          <Collapse in={open}>
            <Stack spacing={0.5}>{navButtons}</Stack>
          </Collapse>
        </>
      ) : (
        <Stack spacing={0.5}>{navButtons}</Stack>
      )}
    </Stack>
  );
}

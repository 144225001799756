import { SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";
import GenericList from "@/components/common/list/genericList";
import ListItemControl, {
  ListItemRadioButton,
} from "@/components/common/list/listItemControl";

export type RadioItem = {
  id: string;
  label: string;
  secondaryLabel?: ReactNode;
  secondaryAction?: ReactNode;
  rightElement?: ReactNode;
  onChange: (id: string) => void;
  isChecked: boolean;
  disabled?: boolean;
};

type RadioListProps = {
  items: RadioItem[];
  sx?: SxProps<Theme>;
  itemSx?: SxProps<Theme>;
  withDivider?: boolean;
};

function RadioList({ items, sx, itemSx, withDivider = false }: RadioListProps) {
  return (
    <GenericList
      items={items.map(
        ({
          id,
          label,
          secondaryLabel,
          secondaryAction,
          isChecked,
          disabled,
          onChange,
          rightElement,
        }) => {
          return (
            <ListItemControl
              key={id}
              secondaryAction={secondaryAction}
              withDivider={withDivider}
              control={
                <ListItemRadioButton
                  id={id}
                  label={label}
                  labelId={`item-radio-${id}`}
                  secondaryLabel={secondaryLabel}
                  onClick={() => onChange(id)}
                  checked={isChecked}
                  disabled={disabled}
                  sx={itemSx}
                  rightElement={rightElement}
                />
              }
            />
          );
        }
      )}
      sx={sx}
    />
  );
}

export default RadioList;

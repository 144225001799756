// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type CreateClientByProviderMutationVariables = Types.Exact<{
  addressLine1?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  addressLine2?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  birthdate?: Types.InputMaybe<Types.Scalars["Date"]["input"]>;
  city?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  email: Types.Scalars["String"]["input"];
  firstName: Types.Scalars["String"]["input"];
  gender?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  lastName: Types.Scalars["String"]["input"];
  medspaId: Types.Scalars["BigInt"]["input"];
  note?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  phone: Types.Scalars["String"]["input"];
  state?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  zipCode?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type CreateClientByProviderMutation = {
  __typename?: "mutation_root";
  createClientByProvider?: {
    __typename?: "CreateClientByProvider";
    message?: string | null;
    ok?: boolean | null;
    clientId?: string | null;
  } | null;
};

export type UpdateClientMutationVariables = Types.Exact<{
  id: Types.Scalars["bigint"]["input"];
  firstName: Types.Scalars["String"]["input"];
  lastName: Types.Scalars["String"]["input"];
  email: Types.Scalars["String"]["input"];
  phone: Types.Scalars["String"]["input"];
  gender?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  birthdate?: Types.InputMaybe<Types.Scalars["date"]["input"]>;
  note?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  addressLine1?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  addressLine2?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  city?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  state?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  zipCode?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type UpdateClientMutation = {
  __typename?: "mutation_root";
  updateClientByPk?: {
    __typename?: "client";
    id: string;
    birthdate?: string | null;
    email: string;
    firstName: string;
    gender?: string | null;
    note: string;
    lastName: string;
    phone: string;
    twilioConversationId: string;
  } | null;
  updateAddress?: {
    __typename?: "addressMutationResponse";
    returning: Array<{
      __typename?: "address";
      addressLine1: string;
      addressLine2: string;
      city: string;
      state: string;
      zipCode: string;
    }>;
  } | null;
};

export type UpdateClientAddressMutationVariables = Types.Exact<{
  id: Types.Scalars["bigint"]["input"];
  addressLine1: Types.Scalars["String"]["input"];
  addressLine2?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  city: Types.Scalars["String"]["input"];
  state: Types.Scalars["String"]["input"];
  zipCode: Types.Scalars["String"]["input"];
}>;

export type UpdateClientAddressMutation = {
  __typename?: "mutation_root";
  updateAddress?: {
    __typename?: "addressMutationResponse";
    returning: Array<{
      __typename?: "address";
      addressLine1: string;
      addressLine2: string;
      city: string;
      state: string;
      zipCode: string;
    }>;
  } | null;
};

export type ClientsByPhoneQueryVariables = Types.Exact<{
  phone: Types.Scalars["String"]["input"];
}>;

export type ClientsByPhoneQuery = {
  __typename?: "query_root";
  client: Array<{ __typename?: "client"; id: string }>;
};

export const CreateClientByProviderDocument = gql`
  mutation CreateClientByProvider(
    $addressLine1: String
    $addressLine2: String
    $birthdate: Date
    $city: String
    $email: String!
    $firstName: String!
    $gender: String
    $lastName: String!
    $medspaId: BigInt!
    $note: String
    $phone: String!
    $state: String
    $zipCode: String
  ) {
    createClientByProvider(
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      birthdate: $birthdate
      city: $city
      email: $email
      firstName: $firstName
      gender: $gender
      lastName: $lastName
      medspaId: $medspaId
      note: $note
      phone: $phone
      state: $state
      zipCode: $zipCode
    ) {
      message
      ok
      clientId
    }
  }
`;
export type CreateClientByProviderMutationFn = Apollo.MutationFunction<
  CreateClientByProviderMutation,
  CreateClientByProviderMutationVariables
>;

/**
 * __useCreateClientByProviderMutation__
 *
 * To run a mutation, you first call `useCreateClientByProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientByProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientByProviderMutation, { data, loading, error }] = useCreateClientByProviderMutation({
 *   variables: {
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      birthdate: // value for 'birthdate'
 *      city: // value for 'city'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      gender: // value for 'gender'
 *      lastName: // value for 'lastName'
 *      medspaId: // value for 'medspaId'
 *      note: // value for 'note'
 *      phone: // value for 'phone'
 *      state: // value for 'state'
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useCreateClientByProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientByProviderMutation,
    CreateClientByProviderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientByProviderMutation,
    CreateClientByProviderMutationVariables
  >(CreateClientByProviderDocument, options);
}
export type CreateClientByProviderMutationHookResult = ReturnType<
  typeof useCreateClientByProviderMutation
>;
export type CreateClientByProviderMutationResult =
  Apollo.MutationResult<CreateClientByProviderMutation>;
export type CreateClientByProviderMutationOptions = Apollo.BaseMutationOptions<
  CreateClientByProviderMutation,
  CreateClientByProviderMutationVariables
>;
export const UpdateClientDocument = gql`
  mutation UpdateClient(
    $id: bigint!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $gender: String
    $birthdate: date
    $note: String
    $addressLine1: String
    $addressLine2: String
    $city: String
    $state: String
    $zipCode: String
  ) {
    updateClientByPk(
      pkColumns: { id: $id }
      _set: {
        birthdate: $birthdate
        email: $email
        firstName: $firstName
        gender: $gender
        lastName: $lastName
        phone: $phone
        note: $note
      }
    ) {
      id
      birthdate
      email
      firstName
      gender
      note
      lastName
      phone
      twilioConversationId
    }
    updateAddress(
      where: { clients: { id: { _eq: $id } } }
      _set: {
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        city: $city
        state: $state
        zipCode: $zipCode
      }
    ) {
      returning {
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
    }
  }
`;
export type UpdateClientMutationFn = Apollo.MutationFunction<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      gender: // value for 'gender'
 *      birthdate: // value for 'birthdate'
 *      note: // value for 'note'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useUpdateClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientMutation,
    UpdateClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientMutation,
    UpdateClientMutationVariables
  >(UpdateClientDocument, options);
}
export type UpdateClientMutationHookResult = ReturnType<
  typeof useUpdateClientMutation
>;
export type UpdateClientMutationResult =
  Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;
export const UpdateClientAddressDocument = gql`
  mutation UpdateClientAddress(
    $id: bigint!
    $addressLine1: String!
    $addressLine2: String
    $city: String!
    $state: String!
    $zipCode: String!
  ) {
    updateAddress(
      where: { clients: { id: { _eq: $id } } }
      _set: {
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        city: $city
        state: $state
        zipCode: $zipCode
      }
    ) {
      returning {
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
    }
  }
`;
export type UpdateClientAddressMutationFn = Apollo.MutationFunction<
  UpdateClientAddressMutation,
  UpdateClientAddressMutationVariables
>;

/**
 * __useUpdateClientAddressMutation__
 *
 * To run a mutation, you first call `useUpdateClientAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientAddressMutation, { data, loading, error }] = useUpdateClientAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useUpdateClientAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientAddressMutation,
    UpdateClientAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientAddressMutation,
    UpdateClientAddressMutationVariables
  >(UpdateClientAddressDocument, options);
}
export type UpdateClientAddressMutationHookResult = ReturnType<
  typeof useUpdateClientAddressMutation
>;
export type UpdateClientAddressMutationResult =
  Apollo.MutationResult<UpdateClientAddressMutation>;
export type UpdateClientAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientAddressMutation,
  UpdateClientAddressMutationVariables
>;
export const ClientsByPhoneDocument = gql`
  query ClientsByPhone($phone: String!) {
    client(where: { phone: { _eq: $phone } }) {
      id
    }
  }
`;

/**
 * __useClientsByPhoneQuery__
 *
 * To run a query within a React component, call `useClientsByPhoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsByPhoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsByPhoneQuery({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useClientsByPhoneQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientsByPhoneQuery,
    ClientsByPhoneQueryVariables
  > &
    (
      | { variables: ClientsByPhoneQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientsByPhoneQuery, ClientsByPhoneQueryVariables>(
    ClientsByPhoneDocument,
    options
  );
}
export function useClientsByPhoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientsByPhoneQuery,
    ClientsByPhoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientsByPhoneQuery, ClientsByPhoneQueryVariables>(
    ClientsByPhoneDocument,
    options
  );
}
export function useClientsByPhoneSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ClientsByPhoneQuery,
        ClientsByPhoneQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClientsByPhoneQuery,
    ClientsByPhoneQueryVariables
  >(ClientsByPhoneDocument, options);
}
export type ClientsByPhoneQueryHookResult = ReturnType<
  typeof useClientsByPhoneQuery
>;
export type ClientsByPhoneLazyQueryHookResult = ReturnType<
  typeof useClientsByPhoneLazyQuery
>;
export type ClientsByPhoneSuspenseQueryHookResult = ReturnType<
  typeof useClientsByPhoneSuspenseQuery
>;
export type ClientsByPhoneQueryResult = Apollo.QueryResult<
  ClientsByPhoneQuery,
  ClientsByPhoneQueryVariables
>;

import { Box, Container, Dialog, Typography } from "@mui/material";
import SlideUpTransition from "@/components/common/modals/slideUpTransition";
import PageHeader from "@/components/common/pageHeader/pageHeader";
import { SignatureData } from "@/components/serviceFlow/visitDetails/confirmations/chartHistory";
import { useVisitChartSignatureDetailsQuery } from "@/graphql/queries/visit/visitChartSignatureDetails.graphql.types";
import { getVisitRangeDates } from "@/utils/charts";
import Signature from "../forms/signature";

export default function ChartSignaturePreview({
  visitId,
  onClose,
}: {
  visitId: string;
  onClose: () => void;
}) {
  const { data } = useVisitChartSignatureDetailsQuery({
    variables: {
      visitId,
    },
    skip: !visitId,
    fetchPolicy: "cache-and-network",
  });

  if (!data) return null;

  const {
    appointment,
    medspa,
    client,
    lastSignature: [signature],
  } = data.visitByPk;

  const visitDate = getVisitRangeDates(
    appointment.startTime,
    appointment.endTime,
    medspa.timezone
  );

  return (
    <Dialog open fullScreen TransitionComponent={SlideUpTransition}>
      <Container
        sx={{
          pb: "10px",
          bgcolor: "background.paper",
          maxHeight: "-webkit-fill-available",
        }}
      >
        <PageHeader
          title="Chart Signature preview"
          goBackStrategy="onClick"
          goBackOnClick={onClose}
        />
        <Box sx={{ width: "100%", height: "100%" }}>
          <Typography variant="h3">
            {client.fullName} • {visitDate}
          </Typography>
          <Signature
            signatureUrl={signature.additionalInfo.signatureUrl}
            signedAt={signature.created}
            signedText="Signed on"
            displayName={signature.userMedspa.user.fullName}
            withTime
          />
        </Box>
      </Container>
    </Dialog>
  );
}

export function ChartSignaturePreviewWithData({
  signature,
  onClose,
}: {
  signature: SignatureData;
  onClose: () => void;
}) {
  const { userMedspa, created, additionalInfo } = signature;
  return (
    <Dialog open fullScreen TransitionComponent={SlideUpTransition}>
      <Container
        sx={{
          pb: "10px",
          bgcolor: "background.paper",
          maxHeight: "-webkit-fill-available",
        }}
      >
        <PageHeader
          title="Chart Signature preview"
          goBackStrategy="onClick"
          goBackOnClick={onClose}
        />
        <Box sx={{ width: "100%", height: "100%" }}>
          <Typography variant="h3">
            {userMedspa?.user.fullName ?? "-"}
          </Typography>
          <Signature
            signatureUrl={additionalInfo.signatureUrl}
            signedAt={created}
            signedText="Signed on"
            displayName={userMedspa?.user.fullName ?? "-"}
            withTime
          />
        </Box>
      </Container>
    </Dialog>
  );
}

import { useRouter } from "next/router";
import { FeaturePermission } from "@/__generated__/featurePermissions";
import { hasRole, useUser } from "@/auth/useUser";
import { useSetVisitStatusMutation } from "@/graphql/mutations/setVisitStatus.graphql.types";
import useMarkAsNoShow from "@/hooks/serviceFlow/visitDropdown/useMarkAsNoShow";
import useCompleteVisit from "@/hooks/visits/useCompleteVisit";
import { FRONT_DESK, PROVIDER, VisitStatus } from "@/types";
import useErrorLogger from "@/utils/useErrorLogger";

export const useVisitStatusActions = (
  visitId: string,
  visitStatus: VisitStatus,
  isVisitEditable: boolean,
  canCompleteVisit: boolean,
  invoice?: { id?: string }
) => {
  const logError = useErrorLogger();
  const {
    query: { slug },
  } = useRouter();
  const { user, hasFeaturePermission, newPermissionsEnabledForUser } =
    useUser();

  const [setVisitStatus] = useSetVisitStatusMutation();

  const canMarkVisitScheduled = newPermissionsEnabledForUser
    ? hasFeaturePermission(FeaturePermission.MARK_VISIT_SCHEDULED)
    : hasRole(user, [PROVIDER]);

  const canEditAppointment = newPermissionsEnabledForUser
    ? hasFeaturePermission(FeaturePermission.EDIT_APPOINTMENT)
    : hasRole(user, [PROVIDER, FRONT_DESK]);

  const markVisitAsNoShow = useMarkAsNoShow(visitId);
  const completeVisit = useCompleteVisit({
    id: visitId,
    status: visitStatus,
    invoice,
  });

  const handleChangeVisitStatus = async (status: VisitStatus) => {
    try {
      await setVisitStatus({
        variables: {
          id: visitId || (slug as string),
          status,
        },
      });
    } catch (errors) {
      logError(errors);
    }
  };

  const canFinalizeVisit = isVisitEditable && canCompleteVisit;

  const actions = [
    {
      component: "Go to checkout",
      onClick: completeVisit,
      disabled: !canFinalizeVisit,
    },
    {
      component: "Mark as scheduled",
      onClick: () => handleChangeVisitStatus(VisitStatus.SCHEDULED),
      disabled: !(
        [VisitStatus.COMPLETED, VisitStatus.NO_SHOW].includes(visitStatus) &&
        canMarkVisitScheduled
      ),
    },
    {
      component: "Mark as confirmed",
      onClick: () => handleChangeVisitStatus(VisitStatus.CONFIRMED),
      disabled: !(visitStatus === VisitStatus.SCHEDULED && canEditAppointment),
    },
    {
      component: "Mark as no-show",
      onClick: markVisitAsNoShow,
      disabled: !canFinalizeVisit,
      divider: true,
    },
  ].filter((item) => !item.disabled);
  const lastAction = actions[actions.length - 1];
  if (lastAction) lastAction.divider = true;

  return actions;
};

import { useStripe } from "@stripe/react-stripe-js";
import { useRouter } from "next/router";
import { toast } from "react-hot-toast";
import { useUser } from "@/auth/useUser";
import { useChargeNoShowFeeMutation } from "@/graphql/mutations/chargeNoShowFee.graphql.types";
import { useSendNoShowFeeCommunicationMutation } from "@/graphql/mutations/sendNoShowFeeCommunication.graphql.types";
import useErrorLogger from "@/utils/useErrorLogger";

export default function useChargeNoShowFee(visitId: string) {
  const logError = useErrorLogger();
  const { push } = useRouter();
  const { medspa } = useUser();
  const stripe = useStripe();
  const [chargeNoShowFeeMutation] = useChargeNoShowFeeMutation();
  const [sendNoShowFeeCommunicationMutation] =
    useSendNoShowFeeCommunicationMutation();

  const chargeFee = async (sendCommunication: boolean) => {
    if (!stripe) {
      toast.error("Stripe account for this MedSpa is not set up.");
      return;
    }
    const { data } = await chargeNoShowFeeMutation({
      variables: {
        visitId,
      },
    });

    const { paymentIntent, payment, invoice, selectedCardId } =
      data.chargeNoShowFee;

    stripe
      .confirmCardPayment(paymentIntent.clientSecret, {
        payment_method: selectedCardId,
      })
      .then((value) => {
        if (sendCommunication && !value.error) {
          sendNoShowFeeCommunicationMutation({
            variables: {
              visitId,
            },
          });
        }
      })
      .catch(logError);

    push(`/${medspa}/invoices/${invoice.id}/payments/${payment.id}`);
  };

  return chargeFee;
}

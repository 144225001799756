import { useAuth } from "@clerk/nextjs";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import { ReactNode, useMemo } from "react";
import { useFeaturePermissions } from "@/auth/useFeaturePermissions";
import { useGuardUrlPermissionOnRouteChange } from "@/auth/useGuardUrlPermissionOnRouteChange";
import { useUrlPermissions } from "@/auth/useUrlPermissions";
import { PROVIDER } from "@/types";
import { isPublicRoute } from "@/utils/router";
import LandingView from "@/views/landingView";
import { useAuthState } from "./useAuthState";
import { UseUserResult, UserAuthContext } from "./useUser";

export const isSignInPage = (pathname: string) =>
  pathname.startsWith(process.env.NEXT_PUBLIC_CLERK_SIGN_IN_URL);

const UserAuthContextProvider = ({ children }: { children: ReactNode }) => {
  const pathname = usePathname();

  const { isLoaded: isClerkLoaded, isSignedIn } = useAuth();
  const {
    user,
    userMedspaData,
    activeUserMedspa,
    isLoading,
    error,
    onMedspaChange,
  } = useAuthState();

  const { hasUrlPermission, urlPermissions } = useUrlPermissions(
    activeUserMedspa?.id
  );
  const { hasFeaturePermission } = useFeaturePermissions(activeUserMedspa?.id);

  useGuardUrlPermissionOnRouteChange(
    !!activeUserMedspa?.roleId,
    hasUrlPermission,
    urlPermissions
  );

  const loading = useMemo(() => {
    if (isSignInPage(pathname)) return false;
    if (!isClerkLoaded) return true;

    return Boolean(!isSignedIn || pathname === "/" || isLoading);
  }, [pathname, isClerkLoaded, isSignedIn, isLoading]);

  const value: UseUserResult = useMemo(() => {
    if (!user) {
      return {
        loading,
        onMedspaChange,
        hasFeaturePermission,
        hasUrlPermission,
      };
    }

    const activeMedspaId = activeUserMedspa ? activeUserMedspa.medspa.id : null;

    return {
      user,
      loading,
      medspa: activeMedspaId,
      onMedspaChange,
      additionalUserDetails: userMedspaData,
      providerUserMedspa: userMedspaData
        ? userMedspaData.userMedspa.find(
            (um) => um.medspa.id === activeMedspaId && um.role === PROVIDER
          )
        : null,
      userMedspa: activeUserMedspa,
      hasFeaturePermission,
      hasUrlPermission,
      newPermissionsEnabledForUser: !!activeUserMedspa?.roleId,
    };
  }, [
    activeUserMedspa,
    user,
    loading,
    onMedspaChange,
    userMedspaData,
    hasFeaturePermission,
    hasUrlPermission,
  ]);

  return (
    <UserAuthContext.Provider value={value}>
      {loading || error ? <LandingView error={error} /> : children}
    </UserAuthContext.Provider>
  );
};

export const UserAuthContextProviderWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  const router = useRouter();

  // Auth context has to be loaded on "/" route, to check if the user is authenticated,
  // so it's not really a public route despite what isPublicRoute says
  if (router.pathname !== "/" && isPublicRoute(router.pathname)) {
    return (
      // temporary solution until DEV-4592 will produce something more sophisticated
      <UserAuthContext.Provider value={{ loading: false }}>
        {children}
      </UserAuthContext.Provider>
    );
  }

  return <UserAuthContextProvider>{children}</UserAuthContextProvider>;
};

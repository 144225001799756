import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";
import { ReactNode } from "react";
import { BLUE, SALMON, YELLOW } from "@/config/mui/colorPalette";
import { CrossCircleIcon, InfoCircleIcon, WarningCircleIcon } from "../icons";

export type Info = {
  bgcolor: string;
  borderColor: string;
  icon: ReactNode;
  textColor: string;
};

export enum Variant {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export const AdditionalInfoStyle: {
  [key in Variant]: Info;
} = {
  [Variant.INFO]: {
    bgcolor: BLUE[20],
    borderColor: BLUE[80],
    icon: <InfoCircleIcon size={20} color={BLUE[100]} />,
    textColor: BLUE[100],
  },
  [Variant.WARNING]: {
    bgcolor: YELLOW[20],
    borderColor: YELLOW[80],
    icon: <WarningCircleIcon size={20} color={YELLOW[100]} />,
    textColor: YELLOW[100],
  },
  [Variant.ERROR]: {
    bgcolor: SALMON[20],
    borderColor: SALMON[80],
    icon: <CrossCircleIcon size={20} color={SALMON[100]} />,
    textColor: SALMON[100],
  },
};

export default function AdditionalInfo({
  variant,
  text,
  sx,
}: {
  variant: "info" | "warning" | "error";
  text: ReactNode;
  sx?: SxProps<Theme>;
}) {
  const { borderColor, bgcolor, icon, textColor } =
    AdditionalInfoStyle[variant];

  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        alignItems: "flex-start",
        border: `1.5px solid ${borderColor}`,
        bgcolor: bgcolor,
        p: 1,
        mt: 2,
        borderRadius: 3,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon}
      </Box>
      <Typography
        component="div"
        variant="paragraphSmall"
        sx={{
          textAlign: "left",
          alignSelf: "center",
          color: textColor,
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
}

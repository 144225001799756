// @ts-nocheck
import { gql } from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

export type ClientPaymentPlansActionsFieldsFragment = {
  __typename?: "visit";
  client: {
    __typename?: "client";
    id: string;
    interestedInPaymentPlans?: boolean | null;
    cherryApplicationLinkLastSentAt?: string | null;
    currentCherryApplicationRecord?: Array<{
      __typename?: "ClientCherryApplicationRecord";
      id: string;
      status: string;
      expiresAt?: string | null;
    }> | null;
  };
};

export const ClientPaymentPlansActionsFieldsFragmentDoc = gql`
  fragment ClientPaymentPlansActionsFields on visit {
    client {
      id
      interestedInPaymentPlans
      cherryApplicationLinkLastSentAt
      currentCherryApplicationRecord {
        id
        status
        expiresAt
      }
    }
  }
`;

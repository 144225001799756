import { useEffect, EffectCallback, DependencyList } from "react";

/**
 * A custom hook that runs the provided effect callback only when the specified dependencies change.
 * This hook is similar to `useEffect`, but it allows you to limit the dependencies that trigger the effect.
 *
 * Note: This implementation does not support cleanup effects. If your effect callback
 * returns a cleanup function, it will not be executed.
 *
 * @param callback - The effect callback function to run when dependencies change.
 * @param dependencies - An array of dependencies that will trigger the effect when they change.
 */
export const useSelectiveEffect = (
  callback: EffectCallback,
  dependencies: DependencyList
) => {
  useEffect(() => {
    callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

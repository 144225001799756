import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import router, { useRouter } from "next/router";
import { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { ArrowSmallLeft } from "react-swm-icon-pack";
import { FeaturePermission } from "@/__generated__/featurePermissions";
import { useUser } from "@/auth/useUser";
import { type TimeSelectorRef } from "@/components/booking/timeSelector/timeSelector";
import {
  TimeSelectorProvider,
  useTimeSelectorContext,
} from "@/components/booking/timeSelector/timeSelectorContext";
import {
  Drawer,
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  DrawerRef,
} from "@/components/common/drawer";
import { useConfirm } from "@/components/providers/confirmProvider";
import ClientForm from "@/components/serviceFlow/clientDetails/forms/clientForm";
import { useCategoriesWithClientIndicationsQuery } from "@/graphql/queries/categories.graphql.types";
import { VisitOverviewQuery } from "@/graphql/queries/visit/visitOverview.graphql.types";
import { useInitializeBookingDrawer } from "@/hooks/booking/createVisitDrawer/useInitializeBookingDrawer";
import { useClients } from "@/hooks/clients/useClients";
import useClientsSorting from "@/hooks/clients/useClientsSorting";
import usePreselectedClients from "@/hooks/common/usePreselectedClients";
import useAction from "@/hooks/misc/useAction";
import useDatesValidation from "@/hooks/misc/useDatesValidation";
import useSearchQuery from "@/hooks/misc/useSearchQuery";
import useCreateClientForm from "@/hooks/serviceFlow/clients/useCreateClientForm";
import useCreateVisit, {
  ClientForVisitCreation,
  Provider,
} from "@/hooks/visits/useCreateVisit";
import useFrontDeskBooking from "@/hooks/visits/useFrontDeskBooking";
import useErrorLogger from "@/utils/useErrorLogger";
import { CreateClientInputs } from "@/views/serviceFlow/clients/createClientView";
import { CreateVisitDrawerForm } from "./createVisitDrawer/createVisitDrawerForm";
import { CreateVisitDrawerSuccess } from "./createVisitDrawer/createVisitDrawerSuccess";
import { CreateVisitDrawerTimeSelection } from "./createVisitDrawer/createVisitDrawerTimeSelection";

export type InitializeDrawerData = {
  client?: VisitOverviewQuery["visitByPk"]["client"];
  appointment?: Partial<VisitOverviewQuery["visitByPk"]["appointment"]>;
  serviceMenuItemLines?: VisitOverviewQuery["visitByPk"]["serviceMenuItemLines"];
  calendarClickDate?: string;
  provider?: Provider;
};

type Props = {
  open?: boolean;
  onClose?: () => void;
  initDrawerVisitData?: InitializeDrawerData;
};

const WEEKS_TO_ADD_FOR_REBOOKING = 12;
export const DEFAULT_CREATE_VISIT_DRAWER_WIDTH = "360px";

export enum DrawerStep {
  TIME_SELECTION = "TIME_SELECTION",
  DEFAULT = "DEFAULT",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  CREATE_CLIENT = "CREATE_CLIENT",
}

const CreateVisitDrawerImpl = ({
  open,
  onClose,
  initDrawerVisitData,
}: Props) => {
  const {
    query: { slug },
  } = useRouter();

  const visitId = typeof slug === "string" ? slug : undefined;

  const theme = useTheme();
  const [drawerStep, setDrawerStep] = useState(DrawerStep.DEFAULT);
  const logError = useErrorLogger();
  const { medspa } = useUser();
  const [createVisitResult, setCreateVisitResult] =
    useState<Awaited<ReturnType<typeof handleCreateVisit>>["data"]>(null);

  const { getConfirm } = useConfirm();

  const [telehealthDetailsOpen, setTelehealthDetailsOpen] =
    useState<boolean>(false);

  const {
    handleCreateVisit,
    handleServicesSelection,
    client,
    setClient,
    services,
    disabledServiceIds,
    start,
    setStart,
    end,
    setEnd,
    servicesDuration,
    calendarEvents,
    provider,
    providers,
    setProvider,
    providerBusinessHours,
    resources,

    // telehealth visit details
    telehealthType,
    setTelehealthType,
    telehealthVideoLink,
    setTelehealthVideoLink,
    telehealthAdditionalDetails,
    setTelehealthAdditionalDetails,
    isTelehealthVisitDetailsValid,
    setIsTelehealthVisitDetailsValid,
  } = useCreateVisit();

  const clientId = client?.id ?? "";

  const isRebooking = !!visitId && !!initDrawerVisitData;

  const usesResources =
    resources?.serviceDevices?.length > 0 ||
    resources?.medspaRooms?.isRoomRequired;

  const confirmResourceOverbooking = async (action: () => Promise<void>) => {
    const roomOverBooked =
      resources?.medspaRooms?.availableRooms === 0 &&
      resources?.medspaRooms?.isRoomRequired;

    const deviceOverBooked = resources?.serviceDevices?.some(
      (device) => device?.availableDevices === 0
    );

    if (roomOverBooked || deviceOverBooked) {
      let overBookedResources = "";
      if (roomOverBooked && deviceOverBooked) {
        overBookedResources = "device and room";
      } else {
        overBookedResources = roomOverBooked ? "room" : "device";
      }

      const ok = await getConfirm({
        title: `You are over booking a ${overBookedResources}`,
        description: `You are double booking a ${overBookedResources} with this appointment. Are you sure you want to continue with this booking?`,
        discardButtonText: "No, go back",
        confirmButtonText: "Confirm booking",
      });

      if (!ok) return;
    }
    await action();
  };

  const { data: categoriesData } = useCategoriesWithClientIndicationsQuery({
    variables: {
      medspaId: medspa,
      clientId,
    },
    fetchPolicy: "cache-and-network",
    skip: !medspa || !clientId,
  });

  const {
    isOpen: isTimeSelectorOpen,
    closeSelector,
    setStart: setStartTimeSelector,
    setEnd: setEndTimeSelector,
    dateRange,
  } = useTimeSelectorContext();

  const { checkFollowupVisitBooked } = useInitializeBookingDrawer({
    isDrawerOpen: open,
    initData: initDrawerVisitData,
    medspa,
    weeksToAdd: isRebooking ? WEEKS_TO_ADD_FOR_REBOOKING : undefined,
    setClient,
    setServices: handleServicesSelection,
    setStart,
    setEnd,
    setProvider,
  });

  const setNewClientOnDropdown = (clientId, details) => {
    setClient({
      id: clientId,
      ...details,
    });
    setDrawerStep(DrawerStep.DEFAULT);
  };

  const {
    control: createClientControl,
    setValue: createClientSetValue,
    trigger: createClientTrigger,
    onSubmit: createClientOnSubmit,
    loading: createClientLoading,
    handleSubmit: createClientHandleSubmit,
  } = useCreateClientForm({
    onSubmitSuccess: setNewClientOnDropdown,
  });

  const servicesWithCategory = useMemo(
    () =>
      categoriesData?.serviceCategory.flatMap((serviceCategory) =>
        serviceCategory.medspaServiceMenuItems.map((service) => ({
          ...service,
          categoryData: {
            id: serviceCategory.id,
            name: serviceCategory.name,
          },
        }))
      ),
    [categoriesData]
  );

  const selectedServices = useMemo(
    () => servicesWithCategory?.filter(({ id }) => services.includes(id)),
    [servicesWithCategory, services]
  );

  const areSelectedServicesSameApptType = useMemo(() => {
    return (
      !selectedServices ||
      selectedServices.length === 0 ||
      selectedServices
        .map((service) => service.appointmentType)
        .reduce((acc, type) => acc.add(type), new Set()).size === 1
    );
  }, [selectedServices]);

  const handleRemoveService = (serviceId: string) => {
    handleServicesSelection(
      services.filter((id) => id !== serviceId),
      start
    );
  };

  const handleGoToVisit = () => {
    if (!createVisitResult?.createSchedulingAppointment?.visitId) return;
    router.push(
      `/${medspa}/visits/${createVisitResult?.createSchedulingAppointment?.visitId}`
    );
  };

  const handleCreateNewVisitClick = async () => {
    const toastId = toast.loading("Booking the appointment...");

    try {
      const { data } = await handleCreateVisit();

      toast.success("The appointment has been booked successfully!", {
        id: toastId,
      });
      setCreateVisitResult(data);
      setDrawerStep(DrawerStep.SUCCESS);
    } catch (errors) {
      toast.error(
        "We couldn't book the appointment at this time. Please try again later.",
        { id: toastId }
      );
      logError(`Exception while creating a visit: ${errors}`);
    }
  };

  const handleCreateRebookingVisitClick = async () => {
    const toastId = toast.loading("Rebooking next appointment...");

    try {
      const { data } = await handleCreateVisit();

      if (visitId) {
        await checkFollowupVisitBooked({
          variables: {
            parentVisitId: visitId,
          },
          refetchQueries: ["VisitOverview"],
        });
      }
      toast.success("The appointment has been rebooked successfully!", {
        id: toastId,
      });
      setCreateVisitResult(data);
      setDrawerStep(DrawerStep.SUCCESS);
    } catch (errors) {
      toast.error(
        "We couldn't rebook an appointment at this time. Please try again later.",
        { id: toastId }
      );
      logError(`Exception while rebooking a visit: ${errors}`);
    }
  };

  const handleCreateVisitClick = isRebooking
    ? handleCreateRebookingVisitClick
    : handleCreateNewVisitClick;

  const { isFrontDesk, getClientsCreditCard, bookAsFrontDesk } =
    useFrontDeskBooking(
      start,
      end,
      client,
      provider?.id,
      services,
      handleCreateVisitClick
    );

  const { hasFeaturePermission, newPermissionsEnabledForUser } = useUser();

  const confirmCreateVisitOnFrontDesk = async () => {
    const ok = await getConfirm({
      title: `You are about to complete a booking`,
      description: `You are completing a booking for ${client?.firstName} ${client?.lastName} without generating the CC link. Are you sure you want to continue with this booking?`,
      discardButtonText: "No, go back",
      confirmButtonText: "Confirm booking",
      confirmButtonDisabled: isLoadingCompleteBooking,
    });

    if (!ok) return;
    await handleCreateVisitClick();
  };

  const hasCollectingCCLinkPermission: boolean = newPermissionsEnabledForUser
    ? hasFeaturePermission(FeaturePermission.GET_CLIENT_CREDIT_CARD_LINK)
    : isFrontDesk;

  const hasConfirmCreateVisitOnFrontDeskPermission: boolean =
    newPermissionsEnabledForUser
      ? hasFeaturePermission(FeaturePermission.CONFIRM_CREATE_VISIT_ON_FD)
      : isFrontDesk;

  const onSelectClient = (newClient: ClientForVisitCreation) => {
    if (hasCollectingCCLinkPermission && newClient) {
      // Preload credit card info data for potential collect cc link copy
      getClientsCreditCard(newClient.id);
    }
    setClient(newClient);
  };

  useEffect(() => {
    //* only dispatch if the time selector has changed and the step isn't reflecting
    if (isTimeSelectorOpen && drawerStep !== DrawerStep.TIME_SELECTION) {
      setDrawerStep(DrawerStep.TIME_SELECTION);
    } else if (
      !isTimeSelectorOpen &&
      drawerStep === DrawerStep.TIME_SELECTION
    ) {
      setDrawerStep(DrawerStep.DEFAULT);
    }
  }, [isTimeSelectorOpen, setDrawerStep, drawerStep]);

  const timeSelectorRef = useRef<TimeSelectorRef>(null);

  const { isTimeCorrect } = useDatesValidation(
    start,
    end,
    setEnd,
    servicesDuration
  );

  const {
    handleAction: handleCopyCollectCCLink,
    isLoading: isLoadingCopyCollectCCLink,
  } = useAction(async () => await confirmResourceOverbooking(bookAsFrontDesk));

  const {
    handleAction: handleCompleteBooking,
    isLoading: isLoadingCompleteBooking,
  } = useAction(
    async () =>
      await confirmResourceOverbooking(
        hasConfirmCreateVisitOnFrontDeskPermission
          ? confirmCreateVisitOnFrontDesk
          : handleCreateVisitClick
      )
  );

  const handleCloseDrawer = () => {
    if (isTimeSelectorOpen) {
      closeSelector();
    }
    handleServicesSelection([]);
    setClient(null);
    setProvider(null);
    setDrawerStep(DrawerStep.DEFAULT);
    setCreateVisitResult(null);

    //* use setTimeout to allow enough time for the drawer close transition to finish.
    setTimeout(() => onClose?.(), theme.transitions.duration.leavingScreen);
  };

  const handleCancelTimeSelector = () => timeSelectorRef.current?.cancel();

  const handleSaveTimeSelector = () => timeSelectorRef.current?.confirm();

  useEffect(() => {
    if (start !== dateRange?.start) {
      setStartTimeSelector(start);
    }
    if (end !== dateRange?.end) {
      setEndTimeSelector(end);
      /**
       * Updating the end date in the timeSelectorRef directly to ensure the time selector end date
       * is always in sync with the create visit drawer end date.
       */
      timeSelectorRef.current?.setEnd(end);
    }
  }, [start, end, dateRange, setStartTimeSelector, setEndTimeSelector]);

  const drawerRef = useRef<DrawerRef>(null);

  const {
    searchQuery: clientsSearchQuery,
    handleSearchInput: handleSearchClientsInput,
    searchInputRef: clientsSearchInputRef,
  } = useSearchQuery();

  const { activeSortBy } = useClientsSorting("names-a-z");
  const { clients: clientsData, loading: isLoadingClients } = useClients(
    clientsSearchQuery,
    activeSortBy,
    false
  );

  const clients = usePreselectedClients(clientsData, client);

  const handleCloseOnSuccess = async () => {
    drawerRef.current?.close();

    await router.push(`/${medspa}/visits`);
  };

  useEffect(() => {
    if (open) {
      drawerRef.current?.open();
    }
  }, [open]);

  const getDrawerTitle = () => {
    if (isTimeSelectorOpen) {
      return "Select date & time";
    }
    if (isRebooking) {
      return "Rebook client";
    }
    if (drawerStep === DrawerStep.CREATE_CLIENT) {
      return "Create new client";
    }
    return "Create client appointment";
  };

  return (
    <Drawer ref={drawerRef} onClose={handleCloseDrawer}>
      <DrawerHeader
        title={getDrawerTitle()}
        leadingAction={
          isTimeSelectorOpen ? (
            <IconButton onClick={handleCancelTimeSelector}>
              <ArrowSmallLeft />
            </IconButton>
          ) : undefined
        }
      />
      <DrawerContent>
        <Box
          sx={{
            width: DEFAULT_CREATE_VISIT_DRAWER_WIDTH,
          }}
        />
        {
          {
            [DrawerStep.TIME_SELECTION]: (
              <CreateVisitDrawerTimeSelection
                usesResources={usesResources}
                resources={resources}
                providerBusinessHours={providerBusinessHours}
                start={start}
                servicesDuration={servicesDuration}
                timeSelectorRef={timeSelectorRef}
                calendarEvents={calendarEvents}
                setEnd={setEnd}
                setStart={setStart}
                isRebooking={isRebooking}
              />
            ),
            [DrawerStep.SUCCESS]: (
              <CreateVisitDrawerSuccess
                client={client}
                provider={provider}
                start={start}
                end={end}
                selectedServices={selectedServices}
              />
            ),
            [DrawerStep.DEFAULT]: (
              <CreateVisitDrawerForm
                telehealthDetailsOpen={telehealthDetailsOpen}
                setTelehealthDetailsOpen={setTelehealthDetailsOpen}
                client={client}
                clients={clients}
                isLoadingClients={isLoadingClients}
                clientsSearchInputRef={clientsSearchInputRef}
                clientsSearchQuery={clientsSearchQuery}
                handleSearchClientsInput={handleSearchClientsInput}
                selectedServices={selectedServices}
                disabledServiceIds={disabledServiceIds}
                servicesWithCategory={servicesWithCategory}
                provider={provider}
                providers={providers}
                startDate={start}
                isRebooking={isRebooking}
                telehealthType={telehealthType}
                telehealthVideoLink={telehealthVideoLink}
                telehealthAdditionalDetails={telehealthAdditionalDetails}
                areSelectedServicesSameApptType={
                  areSelectedServicesSameApptType
                }
                onSelectClient={onSelectClient}
                onSelectServices={handleServicesSelection}
                onSelectProvider={setProvider}
                onSelectTime={setStart}
                onRemoveService={handleRemoveService}
                onChangeTelehealthType={setTelehealthType}
                onChangeTelehealthVideoLink={setTelehealthVideoLink}
                onChangeTelehealthAdditionalDetails={
                  setTelehealthAdditionalDetails
                }
                onChangeIsTelehealthVisitDetailsValid={
                  setIsTelehealthVisitDetailsValid
                }
                onCreateClient={() => setDrawerStep(DrawerStep.CREATE_CLIENT)}
              />
            ),
            [DrawerStep.CREATE_CLIENT]: (
              <ClientForm<CreateClientInputs>
                control={createClientControl}
                setValue={createClientSetValue}
                trigger={createClientTrigger}
              />
            ),
          }[drawerStep]
        }
      </DrawerContent>
      <DrawerActions>
        {
          {
            [DrawerStep.TIME_SELECTION]: (
              <>
                <Button
                  onClick={handleCancelTimeSelector}
                  variant="outlined"
                  sx={{ flex: 1 }}
                  size="small"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSaveTimeSelector}
                  variant="contained"
                  sx={{ flex: 1 }}
                  size="small"
                >
                  Save
                </Button>
              </>
            ),
            [DrawerStep.SUCCESS]: (
              <>
                <Button
                  onClick={handleGoToVisit}
                  variant="outlined"
                  sx={{ flex: 1 }}
                  size="small"
                >
                  Go to appointment
                </Button>
                <Button
                  onClick={handleCloseOnSuccess}
                  disabled={!isTimeCorrect}
                  variant="contained"
                  sx={{ flex: 1 }}
                  size="small"
                >
                  Close
                </Button>
              </>
            ),
            [DrawerStep.DEFAULT]: (
              <Stack
                sx={{
                  flex: 1,
                }}
              >
                {hasCollectingCCLinkPermission && (
                  <Button
                    variant="text"
                    color="primary"
                    fullWidth
                    disabled={
                      !client ||
                      !services.length ||
                      !provider ||
                      !dateRange ||
                      isLoadingCompleteBooking ||
                      isLoadingCopyCollectCCLink ||
                      (telehealthType && !isTelehealthVisitDetailsValid) ||
                      !areSelectedServicesSameApptType
                    }
                    onClick={handleCopyCollectCCLink}
                    startIcon={
                      <CircularProgress
                        size="20px"
                        color="inherit"
                        sx={{ opacity: isLoadingCopyCollectCCLink ? 1 : 0 }}
                      />
                    }
                    sx={{
                      mb: 1,
                    }}
                  >
                    <Typography variant="buttonSmall">
                      Copy collect CC link to clipboard
                    </Typography>
                  </Button>
                )}
                <Stack
                  direction="row"
                  sx={{
                    gap: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    disabled={
                      isLoadingCompleteBooking || isLoadingCopyCollectCCLink
                    }
                    sx={{ flex: 1 }}
                    size="small"
                    onClick={() => drawerRef.current?.close()}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      !client ||
                      !services.length ||
                      !provider ||
                      !dateRange ||
                      isLoadingCompleteBooking ||
                      isLoadingCopyCollectCCLink ||
                      (telehealthType && !isTelehealthVisitDetailsValid) ||
                      !areSelectedServicesSameApptType
                    }
                    variant="contained"
                    sx={{ flex: 1 }}
                    size="small"
                    onClick={handleCompleteBooking}
                  >
                    {isLoadingCompleteBooking ? (
                      <CircularProgress size="20px" color="inherit" />
                    ) : (
                      <>
                        {hasCollectingCCLinkPermission
                          ? "Book without cc link"
                          : "Complete booking"}
                      </>
                    )}
                  </Button>
                </Stack>
              </Stack>
            ),
            [DrawerStep.CREATE_CLIENT]: (
              <>
                <Button
                  onClick={() => setDrawerStep(DrawerStep.DEFAULT)}
                  variant="outlined"
                  sx={{ flex: 1 }}
                  size="small"
                >
                  Cancel
                </Button>
                <Button
                  onClick={createClientHandleSubmit(createClientOnSubmit)}
                  variant="contained"
                  sx={{ flex: 1 }}
                  size="small"
                  disabled={createClientLoading}
                >
                  Create client
                </Button>
              </>
            ),
          }[drawerStep]
        }
      </DrawerActions>
    </Drawer>
  );
};

const CreateVisitDrawer = (props: Props) => (
  <TimeSelectorProvider>
    <CreateVisitDrawerImpl {...props} />
  </TimeSelectorProvider>
);

export default CreateVisitDrawer;

import { Conversation } from "@twilio/conversations";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FeaturePermission } from "@/__generated__/featurePermissions";
import { OrderBy } from "@/__generated__/schema.graphql.types";
import { hasRole, useUser } from "@/auth/useUser";
import { useClientsQuery } from "@/graphql/queries/clients.graphql.types";
import { useTwilioTokenQuery } from "@/graphql/queries/twilioToken.graphql.types";
import useJoinConversation from "@/hooks/messages/useJoinConversation";
import { useNotifications } from "@/hooks/messages/useNotifications";
import { useTwilioClient } from "@/hooks/messages/useTwilioClient";
import { PROVIDER } from "@/types";
import { ClientState, MessagesContextType } from "@/types/messages";

const defaultClientState: ClientState = {
  state: "loading",
  isLongLoading: false,
  connectionState: "connecting",
};

export const MessagesContext = createContext<MessagesContextType>(null);

export const useMessagesContextState = () => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [lastMessageSid, setLastMessageSid] = useState("");
  const [clientState, setClientState] =
    useState<ClientState>(defaultClientState);

  const { medspa, user, newPermissionsEnabledForUser, hasFeaturePermission } =
    useUser();
  const isProvider = useMemo(() => hasRole(user, [PROVIDER]), [user]);
  const canViewMessages = newPermissionsEnabledForUser
    ? hasFeaturePermission(FeaturePermission.VIEW_MESSAGES)
    : isProvider;

  const { data: clientsData } = useClientsQuery({
    variables: {
      orderBy: [{ firstName: OrderBy.Asc }],
      medspaId: medspa,
    },
    skip: !user || !medspa,
    fetchPolicy: "network-only",
  });
  const clientsLoaded = !!clientsData;

  const {
    data: twilioData,
    refetch: refreshToken,
    previousData: previousTwilioData,
  } = useTwilioTokenQuery({
    variables: { id: medspa },
    skip: !medspa || !user || !canViewMessages,
  });

  const twilioToken =
    twilioData?.medspaByPk?.additionalInfo?.twilioConversationsToken;
  const oldTwilioToken =
    previousTwilioData?.medspaByPk?.additionalInfo?.twilioConversationsToken;
  const isStaleToken = twilioToken === oldTwilioToken;

  const { client, initClient, shutdown, updateToken } = useTwilioClient({
    onStateChange: setClientState,
    onConversationsUpdate: setConversations,
    onMessageAdded: setLastMessageSid,
    refreshToken,
  });

  const {
    notificationsCount,
    highlevelUnreadMessagesCount,
    calculateNotificationsCount,
    conversationsData,
  } = useNotifications(conversations, Number(medspa));

  const { joinConversation, leaveConversation } = useJoinConversation();

  useEffect(() => {
    if (!twilioToken || !clientsLoaded) return;
    if (isStaleToken) {
      refreshToken();
      return;
    }
    if (client) {
      updateToken(twilioToken);
      return;
    }
    initClient(twilioToken);
  }, [
    twilioToken,
    clientsLoaded,
    isStaleToken,
    client,
    initClient,
    updateToken,
    refreshToken,
  ]);

  useEffect(() => {
    if (!user && client) {
      shutdown();
      setConversations([]);
      setLastMessageSid("");
      setClientState(defaultClientState);
    }
  }, [user, client, shutdown]);

  return useMemo(
    () => ({
      conversations,
      conversationsData,
      clients: clientsData?.client,
      clientState,
      lastMessageSid,
      calculateNotificationsCount,
      notificationsCount,
      joinConversation,
      leaveConversation,
      highlevelUnreadMessagesCount,
    }),
    [
      conversations,
      conversationsData,
      clientsData?.client,
      clientState,
      lastMessageSid,
      calculateNotificationsCount,
      notificationsCount,
      joinConversation,
      leaveConversation,
      highlevelUnreadMessagesCount,
    ]
  );
};

export const MessagesContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const contextValue = useMessagesContextState();
  return (
    <MessagesContext.Provider value={contextValue}>
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessagesContext = (): MessagesContextType => {
  const context = useContext(MessagesContext);
  if (!context) {
    throw new Error("Must be used inside a MessageContextProvider");
  }
  return context;
};

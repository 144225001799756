import { useUser } from "@/auth/useUser";
import { useClientAccessToken } from "@/contexts/clientAccessTokenContext";
import { useMedSpaFeatureFlagsQuery } from "@/graphql/queries/medspaFeatureFlags.graphql.types";
import { useMedspaFeatureFlagsByClientAccessTokenQuery } from "@/graphql/queries/medspaFeatureFlagsByClientAccessToken.graphql.types";
import { FeatureFlags } from "@/utils/featureFlags";
import { getStripeIdsFromUserMedspa } from "@/utils/stripe";

// WARNING: DO NOT USE ON THE PUBLIC BOOKING PAGE - USE `medspaInfo.additionalPublicInfo.enabledFeatures` INSTEAD
export default function useFeatureFlags() {
  const { medspa, additionalUserDetails } = useUser();

  const { clientAccessToken: token, getTokenContext } = useClientAccessToken();

  const { stripeFinancialAccountId } =
    getStripeIdsFromUserMedspa(additionalUserDetails?.userMedspa, medspa) || {};
  const { data } = useMedSpaFeatureFlagsQuery({
    variables: {
      medspaId: medspa,
    },
    skip: !medspa,
  });

  const { data: clientAccessTokenData } =
    useMedspaFeatureFlagsByClientAccessTokenQuery({
      variables: {
        token: token as string,
      },
      skip: !token,
      ...getTokenContext(),
    });

  const { enabledFeatures = [] } =
    data?.medspaPublicAdditionalInfo ||
    clientAccessTokenData?.clientAccessTokenByPk?.client.medspa
      .additionalPublicInfo ||
    {};

  return {
    // return all `enabledFeatures` so that is can used more directly my components
    enabledFeatures,
    // boolean to check if feature flags are still loading from BE
    featureFlagsLoading: !data && !clientAccessTokenData,
    // below are specific feature flags making it easy to destructure in components and check if a feature is enabled
    balanceEnabled:
      enabledFeatures.includes(FeatureFlags.BALANCE_V1) &&
      !!stripeFinancialAccountId,
    cherryPaymentsEnabled: enabledFeatures.includes(
      FeatureFlags.CHERRY_PAYMENTS_V1
    ),
    packagesAllowRetailProducts: enabledFeatures.includes(
      FeatureFlags.PACKAGES_ALLOW_RETAIL_PRODUCTS
    ),
    messagingMaintenance: enabledFeatures.includes(
      FeatureFlags.MESSAGING_MAINTENANCE
    ),
    standingOrdersV1Enabled: enabledFeatures.includes(
      FeatureFlags.STANDING_ORDERS_V1
    ),
    affirmPaymentMethodEnabled: enabledFeatures.includes(
      FeatureFlags.AFFIRM_PAYMENT_METHOD
    ),
    venmoPaymentMethodEnabled: enabledFeatures.includes(
      FeatureFlags.VENMO_PAYMENT_METHOD
    ),
    zellePaymentMethodEnabled: enabledFeatures.includes(
      FeatureFlags.ZELLE_PAYMENT_METHOD
    ),
    isMaintenanceScheduled: enabledFeatures.includes(
      FeatureFlags.IS_IN_MAINTENANCE
    ),
    consentFormInEveryAppt: enabledFeatures.includes(
      FeatureFlags.CONSENT_FORM_IN_EVERY_APPT
    ),
    consentFormEnhancementsV1Enabled: enabledFeatures.includes(
      FeatureFlags.CONSENT_FORM_ENHANCEMENTS_V1
    ),
    invoiceSummaryUpdateServicesEnabled: enabledFeatures.includes(
      FeatureFlags.INVOICE_SUMMARY_UPDATE_SERVICES
    ),
    complianceHubV1Enabled: enabledFeatures.includes(
      FeatureFlags.COMPLIANCE_HUB_V1
    ),
    complianceHubV2Enabled: enabledFeatures.includes(
      FeatureFlags.COMPLIANCE_HUB_V2
    ),
    complianceHubV3Enabled: enabledFeatures.includes(
      FeatureFlags.COMPLIANCE_HUB_V3
    ),
    complianceHubV4Enabled: enabledFeatures.includes(
      FeatureFlags.COMPLIANCE_HUB_V4
    ),
    complianceHubApptHistoryEnabled: enabledFeatures.includes(
      FeatureFlags.COMPLIANCE_HUB_APPT_HISTORY
    ),
    complianceMdMeetingsV1Enabled: enabledFeatures.includes(
      FeatureFlags.COMPLIANCE_MD_MEETINGS_V1
    ),
    syncGfeV2Enabled: enabledFeatures.includes(FeatureFlags.SYNC_GFE_V2),
    marketingMessagesV1Enabled: enabledFeatures.includes(
      FeatureFlags.MARKETING_MESSAGES_V1
    ),
    commandAiEnabled: enabledFeatures.includes(FeatureFlags.COMMAND_AI),
    marketingMessagesResponseV1Enabled:
      enabledFeatures.includes(FeatureFlags.MARKETING_MESSAGES_RESPONSE_V1) &&
      enabledFeatures.includes(FeatureFlags.MARKETING_MESSAGES_V1),
    telehealthServicesV1Enabled: enabledFeatures.includes(
      FeatureFlags.TELEHEALTH_SERVICES_V1
    ),
    editInvoiceLinePricesV1Enabled: enabledFeatures.includes(
      FeatureFlags.EDIT_INVOICE_LINE_PRICES_V1
    ),
    orderPrescriptionDrawerV1Enabled: enabledFeatures.includes(
      FeatureFlags.ORDER_PRESCRIPTION_DRAWER_V1
    ),
    configurableNotificationsV1Enabled: enabledFeatures.includes(
      FeatureFlags.CONFIGURABLE_NOTIFICATIONS_V1
    ),
    shopifyPlusEnabled: enabledFeatures.includes(FeatureFlags.SHOPIFY_PLUS),
    onlineBookingDepositsV1Enabled: enabledFeatures.includes(
      FeatureFlags.ONLINE_BOOKING_DEPOSITS_V1
    ),
    newBookingStepperV1Enabled: enabledFeatures.includes(
      FeatureFlags.NEW_BOOKING_STEPPER_V1
    ),
    multipleStripeTerminalsV1Enabled: enabledFeatures.includes(
      FeatureFlags.MULTIPLE_STRIPE_TERMINALS_V1
    ),
    reportABugVerticalNavEnabled: enabledFeatures.includes(
      FeatureFlags.REPORT_A_BUG_VERTICAL_NAV
    ),
    cherryIntegrationV1Enabled: enabledFeatures.includes(
      FeatureFlags.CHERRY_INTEGRATION_V1
    ),
    packagesSuggestionsV1Enabled: enabledFeatures.includes(
      FeatureFlags.PACKAGES_SUGGESTIONS_V1
    ),
    referAndEarnV1Enabled: enabledFeatures.includes(
      FeatureFlags.REFER_AND_EARN_V1
    ),
    lastMinuteAppointmentsEnabled: enabledFeatures.includes(
      FeatureFlags.LAST_MINUTE_APPOINTMENTS_V1
    ),
  };
}

import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useRouter } from "next/router";
import { ReactNode, useState } from "react";
import { toast } from "react-hot-toast";
import { FeaturePermission } from "@/__generated__/featurePermissions";
import { hasRole, useUser } from "@/auth/useUser";
import DropdownMenu, {
  DropdownMenuItems,
} from "@/components/common/dropdownMenu/dropdownMenu";
import {
  MenuHorizontalIcon,
  WarningCircleIcon,
} from "@/components/common/icons";
import { getCherryApplicationStatus } from "@/components/common/paymentPlans/cherryStatus";
import { useConfirm } from "@/components/providers/confirmProvider";
import TelehealthDetailsDialog from "@/components/serviceFlow/visitDetails/telehealth/telehealthDetailsDialog";
import { IS_PRODUCTION } from "@/config";
import { ERROR_PRIMARY, PRIMARY } from "@/config/mui/colorPalette";
import { useDeleteTelehealthVisitDetailsMutation } from "@/graphql/mutations/telehealth/deleteTelehealthVisitDetails.graphql.types";
import { useChargeFeeAdditionalInfoLazyQuery } from "@/graphql/queries/visit/visitDropdown.graphql.types";
import useFeatureFlags from "@/hooks/common/useFeatureFlags";
import useCancelAppointment from "@/hooks/serviceFlow/visitDropdown/useCancelAppointment";
import useChargeLateCancellationFee from "@/hooks/serviceFlow/visitDropdown/useChargeLateCancellationFee";
import { useChartReviewActions } from "@/hooks/serviceFlow/visitDropdown/useChartReviewActions";
import useCurrentVisitDropdownFragment from "@/hooks/serviceFlow/visitDropdown/useCurrentVisitDropdownFragment";
import {
  ClientData,
  usePaymentPlansDropdownMenuItems,
} from "@/hooks/serviceFlow/visitDropdown/usePaymentPlansActions";
import { useStartReviewDropdownItem } from "@/hooks/serviceFlow/visitDropdown/useStartReviewDropdownItem";
import { useVisitStatusActions } from "@/hooks/serviceFlow/visitDropdown/useVisitStatusActions";
import { useIsEligibleToRequestScripts } from "@/hooks/user/useIsEligibleToRequestScripts";
import { useIsEligibleWeightLossPharmacies } from "@/hooks/user/useIsEligibleWeightLossPharmacies";
import {
  ClientCherryApplicationRecordStatus,
  FRONT_DESK,
  InvoiceStatus,
  PROVIDER,
  ReviewStatus,
  TelehealthType,
  VisitStatus,
} from "@/types";
import { REMOVE_TELEHEALTH_DETAILS_CONFIRM_MODAL_CONTENT } from "@/utils/telehealth";
import useErrorLogger from "@/utils/useErrorLogger";
import { getIsLateCancellation } from "@/utils/visit";

type VisitDropdownProps = {
  visitId: string;
  reviewStatus: ReviewStatus;
  visitStatus: VisitStatus;
};

function VisitDropdown({
  visitId,
  reviewStatus,
  visitStatus,
}: VisitDropdownProps) {
  const {
    telehealthServicesV1Enabled,
    orderPrescriptionDrawerV1Enabled,
    cherryIntegrationV1Enabled,
  } = useFeatureFlags();
  const isEligibleToRequestScripts = useIsEligibleToRequestScripts();
  const eligibleWeightLossPharmacies = useIsEligibleWeightLossPharmacies();
  const logError = useErrorLogger();
  const {
    medspa,
    user,
    hasFeaturePermission,
    hasUrlPermission,
    newPermissionsEnabledForUser,
  } = useUser();
  const {
    query: { tab },
    push,
  } = useRouter();
  const { getConfirm } = useConfirm();

  const { data } = useCurrentVisitDropdownFragment(visitId);

  const [getChargeFeeData, { data: chargeFeeData }] =
    useChargeFeeAdditionalInfoLazyQuery({
      variables: {
        visitId,
      },
    });

  const cancelAppointment = useCancelAppointment(visitId);

  const chargeLateCancellationFee = useChargeLateCancellationFee(visitId);

  const [telehealthFormType, setTelehealthFormType] = useState<"add" | "edit">(
    "add"
  );
  const [telehealthModalOpen, setTelehealthModalOpen] = useState(false);

  const [removeTelehealthDetails] = useDeleteTelehealthVisitDetailsMutation();

  const hasCardsOnFile =
    chargeFeeData?.visitByPk?.client.stripeData.stripeCardsList.length > 0;
  const cancellationFeePolicyEnabled =
    chargeFeeData?.visitByPk?.medspa.configuration.cancellationFeePolicyEnabled;
  const isAlreadyInPaymentFlow =
    data?.invoice && data.invoice.status !== InvoiceStatus.DRAFT;
  const clientId = data?.client.id;

  const isEditable = [VisitStatus.SCHEDULED, VisitStatus.CONFIRMED].includes(
    visitStatus
  );
  const canCompleteVisit = newPermissionsEnabledForUser
    ? hasFeaturePermission(FeaturePermission.COMPLETE_VISIT)
    : hasRole(user, [PROVIDER]);

  const isChartSigned = data?.lastSignature?.length > 0;

  const { actions: chartDropdownItems, dialogComponent } =
    useChartReviewActions(
      visitId,
      visitStatus,
      reviewStatus,
      isChartSigned,
      data?.invoice?.id
    );
  const visitStatusActions = useVisitStatusActions(
    visitId,
    visitStatus,
    isEditable,
    canCompleteVisit,
    data?.invoice
  );

  const status = getCherryApplicationStatus(
    data?.client?.currentCherryApplicationRecord?.[0]
      ?.status as ClientCherryApplicationRecordStatus,
    data?.client?.currentCherryApplicationRecord?.[0]?.expiresAt
  );

  const paymentPlansActions = usePaymentPlansDropdownMenuItems(
    data?.client as ClientData,
    status,
    data?.appointment.id
  );

  const paymentPlansDropdownItems = cherryIntegrationV1Enabled
    ? paymentPlansActions
    : [];

  const pendingReview = data?.client?.pendingGfeReview?.[0];
  const lastReviewId = data?.client?.lastGfeReview?.[0]?.id;
  const backUrl = `/${medspa}/visits/${visitId}/${tab ? tab : "overview"}`;

  const startGfeReviewItem = useStartReviewDropdownItem(
    pendingReview as { id: string; asyncReviewedAt?: string },
    lastReviewId,
    clientId,
    backUrl
  );

  const lateCancellationHours =
    chargeFeeData?.visitByPk?.medspa.configuration
      ?.lateCancellationAdvanceHours;

  const isLateCancellation = getIsLateCancellation(
    data?.appointment?.startTime,
    lateCancellationHours
  );

  const getCancelAppointmentLabel = () => {
    return isLateCancellation
      ? "Late cancel appointment"
      : "Cancel appointment";
  };

  const canEditAppointment = newPermissionsEnabledForUser
    ? hasFeaturePermission(FeaturePermission.EDIT_APPOINTMENT)
    : hasRole(user, [PROVIDER, FRONT_DESK]);

  const canManageScripts = newPermissionsEnabledForUser
    ? hasFeaturePermission(FeaturePermission.MANAGE_SCRIPT_REQUESTS)
    : hasRole(user, [PROVIDER]);

  const canManageTelehealthVisitDetails = newPermissionsEnabledForUser
    ? hasFeaturePermission(FeaturePermission.MANAGE_TELEHEALTH_VISIT_DETAILS)
    : hasRole(user, [PROVIDER]);

  const addAdverseReactionUrl = `/${medspa}/visits/${visitId}/adverse-reactions/new`;
  const canAddAdverseReaction = newPermissionsEnabledForUser
    ? hasUrlPermission(addAdverseReactionUrl)
    : hasRole(user, [PROVIDER]);

  const hasTelehealthDetails = !!data.telehealthVisitDetails;
  const isVisitTelehealth = data?.isVisitTelehealth;

  const handleRemoveTelehealthDetails = async () => {
    const confirm = await getConfirm(
      REMOVE_TELEHEALTH_DETAILS_CONFIRM_MODAL_CONTENT
    );
    if (!confirm) return;

    const toastId = toast.loading("Removing telehealth details...");

    try {
      await removeTelehealthDetails({
        variables: {
          visitId: visitId,
        },
        update: (cache) => {
          cache.modify({
            id: cache.identify({ __typename: "visit", id: visitId }),
            fields: {
              telehealthVisitDetails: () => undefined,
            },
          });
        },
      });

      toast.success("Telehealth details have been removed successfully!", {
        id: toastId,
      });
    } catch (e) {
      logError(e);
      toast.error("An error occurred while removing telehealth details", {
        id: toastId,
      });
    }
  };

  const telehealthDetailsInitData = data.telehealthVisitDetails && {
    ...data.telehealthVisitDetails,
    visitType: data.telehealthVisitDetails.visitType as TelehealthType,
  };

  const items: DropdownMenuItems = [
    ...visitStatusActions,
    startGfeReviewItem,
    ...chartDropdownItems,
    ...paymentPlansDropdownItems,
    {
      component: "Request Patient Specific Script",
      onClick: () =>
        push(
          `/${medspa}/weight-loss-order/rn-order?client=${clientId}&visit=${visitId}`
        ),
      disabled: !(isEligibleToRequestScripts && canManageScripts),
    },
    ...(!orderPrescriptionDrawerV1Enabled
      ? [
          ...(!IS_PRODUCTION
            ? [
                {
                  component: "Order Test Form Specific",
                  onClick: () =>
                    push(
                      `/${medspa}/weight-loss-order/test-form?client=${clientId}&visit=${visitId}`
                    ),
                },
              ]
            : []),
          {
            component: "Order Empower Patient Specific Script",
            onClick: () =>
              push(
                `/${medspa}/weight-loss-order/empower?client=${clientId}&visit=${visitId}`
              ),
            disabled: !(
              eligibleWeightLossPharmacies["empower"] && canManageScripts
            ),
          },
          {
            component: "Order Strive Patient Specific",
            onClick: () =>
              push(
                `/${medspa}/weight-loss-order/strive-503a?client=${clientId}&visit=${visitId}`
              ),
            disabled: !(
              eligibleWeightLossPharmacies["strive503A"] && canManageScripts
            ),
          },
          {
            component: "Order Strive Patient Specific",
            onClick: () =>
              push(
                `/${medspa}/weight-loss-order/strive-503b?client=${clientId}&visit=${visitId}`
              ),
            disabled: !(
              eligibleWeightLossPharmacies["strive503B"] && canManageScripts
            ),
          },
        ]
      : []),
    {
      component: "Edit appointment",
      onClick: () => push(`/${medspa}/visits/${visitId}/edit`),
      disabled: !(isEditable && canEditAppointment),
    },
    ...(telehealthServicesV1Enabled && isVisitTelehealth
      ? [
          {
            component: "Add telehealth details",
            onClick: () => {
              setTelehealthFormType("add");
              setTelehealthModalOpen(true);
            },
            disabled: hasTelehealthDetails || !canManageTelehealthVisitDetails,
          },
          {
            component: "Edit telehealth details",
            onClick: () => {
              setTelehealthFormType("edit");
              setTelehealthModalOpen(true);
            },
            disabled: !hasTelehealthDetails || !canManageTelehealthVisitDetails,
          },
          {
            component: "Remove telehealth details",
            onClick: handleRemoveTelehealthDetails,
            disabled: !hasTelehealthDetails || !canManageTelehealthVisitDetails,
          },
        ]
      : []),
    // This is a default one, it could be either "Cancel appointment" or "Late cancel Appointment"
    {
      component: (
        <LazyItem
          isLoading={!chargeFeeData}
          label={getCancelAppointmentLabel()}
        />
      ),
      onClick: () =>
        chargeFeeData && cancelAppointment({ skipFeeChargeAttempt: false }),
      disabled: !(isEditable && canEditAppointment),
    },
    // This one appears only in pair with "Late cancel appointment" as an option for providers to mark as Cancelled while in late cancellation window
    {
      component: (
        <LazyItem isLoading={!chargeFeeData} label="Cancel appointment" />
      ),
      onClick: () =>
        chargeFeeData && cancelAppointment({ skipFeeChargeAttempt: true }),
      disabled: !(isEditable && isLateCancellation && canCompleteVisit),
    },
    {
      component: (
        <LazyItem
          isLoading={!chargeFeeData}
          label="Charge late cancellation fee"
        >
          {cancellationFeePolicyEnabled && !hasCardsOnFile && (
            <Tooltip title="Client has no card on file">
              <IconButton sx={{ p: 0 }}>
                <WarningCircleIcon color={ERROR_PRIMARY} size="24px" />
              </IconButton>
            </Tooltip>
          )}
        </LazyItem>
      ),
      onClick: () => chargeFeeData && chargeLateCancellationFee(),
      disabled:
        !(
          visitStatus === VisitStatus.CANCELLED_LATE &&
          canCompleteVisit &&
          !isAlreadyInPaymentFlow
        ) ||
        (chargeFeeData && !cancellationFeePolicyEnabled),
    },
    {
      component: "Report Adverse Reaction",
      onClick: () => push(addAdverseReactionUrl),
      disabled: !(
        canAddAdverseReaction &&
        ![
          VisitStatus.CANCELLED_LATE,
          VisitStatus.CANCELLED,
          VisitStatus.NO_SHOW,
        ].includes(visitStatus)
      ),
    },
  ];

  const menuItems = items.filter((item) => !item.disabled);

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <DropdownMenu
        type={null}
        onMenuOpen={() => getChargeFeeData()}
        menuItems={menuItems}
        disabled={menuItems.length < 1}
        buttonVariant="icon"
        icon={<MenuHorizontalIcon color={PRIMARY} />}
      />
      {dialogComponent}
      <TelehealthDetailsDialog
        formType={telehealthFormType}
        visitId={visitId}
        initData={telehealthDetailsInitData}
        isOpen={telehealthModalOpen}
        onClose={() => setTelehealthModalOpen(false)}
      />
    </Box>
  );
}

function LazyItem({
  isLoading,
  label,
  children,
}: {
  isLoading: boolean;
  label: string;
  children?: ReactNode;
}) {
  return (
    <Stack direction="row" alignItems="center" gap={1.5}>
      <Typography variant="paragraphSmall">{label}</Typography>

      {isLoading ? (
        <CircularProgress size="16px" color="secondary" />
      ) : (
        children
      )}
    </Stack>
  );
}

export default VisitDropdown;

// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type ClientTrackingByTokenQueryVariables = Types.Exact<{
  token: Types.Scalars["String"]["input"];
}>;

export type ClientTrackingByTokenQuery = {
  __typename?: "query_root";
  clientAccessTokenByPk?: {
    __typename?: "clientAccessToken";
    client: { __typename?: "client"; id: string; email: string };
  } | null;
};

export const ClientTrackingByTokenDocument = gql`
  query ClientTrackingByToken($token: String!) {
    clientAccessTokenByPk(token: $token) {
      client {
        id
        email
      }
    }
  }
`;

/**
 * __useClientTrackingByTokenQuery__
 *
 * To run a query within a React component, call `useClientTrackingByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientTrackingByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientTrackingByTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useClientTrackingByTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientTrackingByTokenQuery,
    ClientTrackingByTokenQueryVariables
  > &
    (
      | { variables: ClientTrackingByTokenQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClientTrackingByTokenQuery,
    ClientTrackingByTokenQueryVariables
  >(ClientTrackingByTokenDocument, options);
}
export function useClientTrackingByTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientTrackingByTokenQuery,
    ClientTrackingByTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientTrackingByTokenQuery,
    ClientTrackingByTokenQueryVariables
  >(ClientTrackingByTokenDocument, options);
}
export function useClientTrackingByTokenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ClientTrackingByTokenQuery,
        ClientTrackingByTokenQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClientTrackingByTokenQuery,
    ClientTrackingByTokenQueryVariables
  >(ClientTrackingByTokenDocument, options);
}
export type ClientTrackingByTokenQueryHookResult = ReturnType<
  typeof useClientTrackingByTokenQuery
>;
export type ClientTrackingByTokenLazyQueryHookResult = ReturnType<
  typeof useClientTrackingByTokenLazyQuery
>;
export type ClientTrackingByTokenSuspenseQueryHookResult = ReturnType<
  typeof useClientTrackingByTokenSuspenseQuery
>;
export type ClientTrackingByTokenQueryResult = Apollo.QueryResult<
  ClientTrackingByTokenQuery,
  ClientTrackingByTokenQueryVariables
>;

import { addMinutes, differenceInMinutes, isBefore } from "date-fns";
import { Dispatch, useEffect, useMemo } from "react";

export default function useDatesValidation(
  start: Date,
  end: Date,
  setEnd: Dispatch<Date>,
  minDuration = 1
) {
  const timeDifference = differenceInMinutes(end, start);
  const startTimeWithMinDuration = useMemo(
    () => addMinutes(start, Math.max(minDuration, 1)),
    [start, minDuration]
  );
  const isEndBeforeOrEqualStart = isBefore(end, startTimeWithMinDuration);

  useEffect(() => {
    if (isEndBeforeOrEqualStart) {
      // Edge case: If appointment starts at 6:00 am and end at 7:00 am, the user can change end hour to 6. In result even though
      // 6:00 am is disabled in picker, the user can still select it. This is a workaround to prevent this from happening.
      setEnd(addMinutes(start, 5));
    }
  }, [isEndBeforeOrEqualStart, start, setEnd]);

  return { isTimeCorrect: timeDifference >= minDuration };
}

// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type UserMedSpaUrlPatternPermissionsQueryVariables = Types.Exact<{
  id: Types.Scalars["bigint"]["input"];
}>;

export type UserMedSpaUrlPatternPermissionsQuery = {
  __typename?: "query_root";
  userMedspaUrlPermissions: Array<{
    __typename?: "UserMedspaUrlPermissions";
    codename?: string | null;
    urlPattern?: string | null;
  }>;
};

export const UserMedSpaUrlPatternPermissionsDocument = gql`
  query UserMedSpaUrlPatternPermissions($id: bigint!) {
    userMedspaUrlPermissions(where: { userMedspaId: { _eq: $id } }) {
      codename
      urlPattern
    }
  }
`;

/**
 * __useUserMedSpaUrlPatternPermissionsQuery__
 *
 * To run a query within a React component, call `useUserMedSpaUrlPatternPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMedSpaUrlPatternPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMedSpaUrlPatternPermissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserMedSpaUrlPatternPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserMedSpaUrlPatternPermissionsQuery,
    UserMedSpaUrlPatternPermissionsQueryVariables
  > &
    (
      | {
          variables: UserMedSpaUrlPatternPermissionsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserMedSpaUrlPatternPermissionsQuery,
    UserMedSpaUrlPatternPermissionsQueryVariables
  >(UserMedSpaUrlPatternPermissionsDocument, options);
}
export function useUserMedSpaUrlPatternPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserMedSpaUrlPatternPermissionsQuery,
    UserMedSpaUrlPatternPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserMedSpaUrlPatternPermissionsQuery,
    UserMedSpaUrlPatternPermissionsQueryVariables
  >(UserMedSpaUrlPatternPermissionsDocument, options);
}
export function useUserMedSpaUrlPatternPermissionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UserMedSpaUrlPatternPermissionsQuery,
        UserMedSpaUrlPatternPermissionsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UserMedSpaUrlPatternPermissionsQuery,
    UserMedSpaUrlPatternPermissionsQueryVariables
  >(UserMedSpaUrlPatternPermissionsDocument, options);
}
export type UserMedSpaUrlPatternPermissionsQueryHookResult = ReturnType<
  typeof useUserMedSpaUrlPatternPermissionsQuery
>;
export type UserMedSpaUrlPatternPermissionsLazyQueryHookResult = ReturnType<
  typeof useUserMedSpaUrlPatternPermissionsLazyQuery
>;
export type UserMedSpaUrlPatternPermissionsSuspenseQueryHookResult = ReturnType<
  typeof useUserMedSpaUrlPatternPermissionsSuspenseQuery
>;
export type UserMedSpaUrlPatternPermissionsQueryResult = Apollo.QueryResult<
  UserMedSpaUrlPatternPermissionsQuery,
  UserMedSpaUrlPatternPermissionsQueryVariables
>;

import { Conversation } from "@twilio/conversations";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSumUnreadHighlevelMessagesQuery } from "@/graphql/queries/highlevelMessages.graphql.types";
import { ConversationData } from "@/utils/messages";
import {
  getNotificationsCount,
  getMutipleRequestsError,
} from "@/utils/messages";
import useErrorLogger from "@/utils/useErrorLogger";

export const useNotifications = (
  conversations: Conversation[],
  medspaId?: number
) => {
  const logError = useErrorLogger();
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [highlevelUnreadMessagesCount, setHighlevelUnreadMessagesCount] =
    useState(0);
  const [conversationsData, setConversationsData] = useState<
    Map<string, ConversationData>
  >(new Map());
  const isCalculatingNotificationsCount = useRef(false);
  const [fetchingHighlevelUnreadMessages, setFetchingHighlevelUnreadMessages] =
    useState(false);

  const { data: highlevelUnreadMessagesData } =
    useSumUnreadHighlevelMessagesQuery({
      variables: { medspaId },
      pollInterval: 60000, // 1 minute
      skip: !medspaId,
    });

  const appendConversationsData = (sid: string, data: ConversationData) => {
    setConversationsData((prev) => {
      const newData = new Map(prev);
      if (newData.has(sid)) {
        newData.set(sid, { ...newData.get(sid), ...data });
      } else {
        newData.set(sid, data);
      }
      return newData;
    });
  };

  const calculateNotificationsCount = useCallback(async () => {
    setFetchingHighlevelUnreadMessages(true);

    if (
      isCalculatingNotificationsCount.current ||
      fetchingHighlevelUnreadMessages
    )
      return;
    if (!conversations.length) return;

    isCalculatingNotificationsCount.current = true;
    const {
      notificationsCount: count,
      getLastMessageErrorsCount,
      getUnreadMessagesCountErrorsCount,
    } = await getNotificationsCount(conversations, appendConversationsData);
    isCalculatingNotificationsCount.current = false;

    if (getLastMessageErrorsCount || getUnreadMessagesCountErrorsCount) {
      logError(
        getMutipleRequestsError(
          "getNotificationsCount()",
          getLastMessageErrorsCount,
          getUnreadMessagesCountErrorsCount
        )
      );
    }
    setNotificationsCount(count);
  }, [conversations, fetchingHighlevelUnreadMessages, logError]);

  useEffect(() => {
    if (!medspaId) {
      setHighlevelUnreadMessagesCount(0);
      return;
    }
    setHighlevelUnreadMessagesCount(
      highlevelUnreadMessagesData?.sumUnreadHighlevelMessages?.count ?? 0
    );
  }, [highlevelUnreadMessagesData, medspaId]);

  return {
    notificationsCount,
    highlevelUnreadMessagesCount,
    calculateNotificationsCount,
    conversationsData,
  };
};

// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type MedspaFeatureFlagsByClientAccessTokenQueryVariables = Types.Exact<{
  token: Types.Scalars["String"]["input"];
}>;

export type MedspaFeatureFlagsByClientAccessTokenQuery = {
  __typename?: "query_root";
  clientAccessTokenByPk?: {
    __typename?: "clientAccessToken";
    client: {
      __typename?: "client";
      id: string;
      medspa: {
        __typename?: "medspa";
        id: string;
        additionalPublicInfo?: {
          __typename?: "MedspaPublicInfoType";
          id: string;
          enabledFeatures: Array<string | null>;
        } | null;
      };
    };
  } | null;
};

export const MedspaFeatureFlagsByClientAccessTokenDocument = gql`
  query MedspaFeatureFlagsByClientAccessToken($token: String!) {
    clientAccessTokenByPk(token: $token) {
      client {
        id
        medspa {
          id
          additionalPublicInfo {
            id
            enabledFeatures
          }
        }
      }
    }
  }
`;

/**
 * __useMedspaFeatureFlagsByClientAccessTokenQuery__
 *
 * To run a query within a React component, call `useMedspaFeatureFlagsByClientAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedspaFeatureFlagsByClientAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedspaFeatureFlagsByClientAccessTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useMedspaFeatureFlagsByClientAccessTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    MedspaFeatureFlagsByClientAccessTokenQuery,
    MedspaFeatureFlagsByClientAccessTokenQueryVariables
  > &
    (
      | {
          variables: MedspaFeatureFlagsByClientAccessTokenQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MedspaFeatureFlagsByClientAccessTokenQuery,
    MedspaFeatureFlagsByClientAccessTokenQueryVariables
  >(MedspaFeatureFlagsByClientAccessTokenDocument, options);
}
export function useMedspaFeatureFlagsByClientAccessTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MedspaFeatureFlagsByClientAccessTokenQuery,
    MedspaFeatureFlagsByClientAccessTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MedspaFeatureFlagsByClientAccessTokenQuery,
    MedspaFeatureFlagsByClientAccessTokenQueryVariables
  >(MedspaFeatureFlagsByClientAccessTokenDocument, options);
}
export function useMedspaFeatureFlagsByClientAccessTokenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MedspaFeatureFlagsByClientAccessTokenQuery,
        MedspaFeatureFlagsByClientAccessTokenQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MedspaFeatureFlagsByClientAccessTokenQuery,
    MedspaFeatureFlagsByClientAccessTokenQueryVariables
  >(MedspaFeatureFlagsByClientAccessTokenDocument, options);
}
export type MedspaFeatureFlagsByClientAccessTokenQueryHookResult = ReturnType<
  typeof useMedspaFeatureFlagsByClientAccessTokenQuery
>;
export type MedspaFeatureFlagsByClientAccessTokenLazyQueryHookResult =
  ReturnType<typeof useMedspaFeatureFlagsByClientAccessTokenLazyQuery>;
export type MedspaFeatureFlagsByClientAccessTokenSuspenseQueryHookResult =
  ReturnType<typeof useMedspaFeatureFlagsByClientAccessTokenSuspenseQuery>;
export type MedspaFeatureFlagsByClientAccessTokenQueryResult =
  Apollo.QueryResult<
    MedspaFeatureFlagsByClientAccessTokenQuery,
    MedspaFeatureFlagsByClientAccessTokenQueryVariables
  >;

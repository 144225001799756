import { FeaturePermission } from "@/__generated__/featurePermissions";
import { hasRole, useUser } from "@/auth/useUser";
import { useProviderInfoQuery } from "@/graphql/queries/user.graphql.types";
import { PROVIDER } from "@/types";

export default function useCurrentProviderInfo() {
  const { user, newPermissionsEnabledForUser, hasFeaturePermission } =
    useUser();
  const { data, ...rest } = useProviderInfoQuery({
    variables: {
      id: user?.id,
    },
    skip:
      !user ||
      (newPermissionsEnabledForUser
        ? !hasFeaturePermission(FeaturePermission.VIEW_PROVIDER_SPECIFIC_INFO)
        : !hasRole(user, [PROVIDER])),
  });

  return {
    providerInfo: data?.userMedspa[0]?.providerInfo,
    data,
    ...rest,
  };
}

// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type MedspaProvidersQueryVariables = Types.Exact<{
  id: Types.Scalars["bigint"]["input"];
}>;

export type MedspaProvidersQuery = {
  __typename?: "query_root";
  medspaByPk?: {
    __typename?: "medspa";
    id: string;
    userMedspas: Array<{
      __typename?: "userMedspa";
      id: string;
      user: {
        __typename?: "user";
        id: string;
        firstName: string;
        lastName: string;
        fullName?: string | null;
      };
    }>;
  } | null;
};

export type MedspasProvidersQueryVariables = Types.Exact<{
  medspaIds?: Types.InputMaybe<
    Array<Types.Scalars["bigint"]["input"]> | Types.Scalars["bigint"]["input"]
  >;
}>;

export type MedspasProvidersQuery = {
  __typename?: "query_root";
  medspa: Array<{
    __typename?: "medspa";
    id: string;
    name: string;
    userMedspas: Array<{
      __typename?: "userMedspa";
      id: string;
      user: { __typename?: "user"; id: string; fullName?: string | null };
    }>;
  }>;
};

export const MedspaProvidersDocument = gql`
  query MedspaProviders($id: bigint!) {
    medspaByPk(id: $id) {
      id
      userMedspas(
        where: {
          active: { _eq: true }
          _or: [
            { featurePermissions: { codename: { _eq: "is_provider" } } }
            { role: { _eq: "provider" } }
          ]
        }
        orderBy: { user: { firstName: ASC } }
      ) {
        id
        user {
          id
          firstName
          lastName
          fullName
        }
      }
    }
  }
`;

/**
 * __useMedspaProvidersQuery__
 *
 * To run a query within a React component, call `useMedspaProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedspaProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedspaProvidersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMedspaProvidersQuery(
  baseOptions: Apollo.QueryHookOptions<
    MedspaProvidersQuery,
    MedspaProvidersQueryVariables
  > &
    (
      | { variables: MedspaProvidersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MedspaProvidersQuery, MedspaProvidersQueryVariables>(
    MedspaProvidersDocument,
    options
  );
}
export function useMedspaProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MedspaProvidersQuery,
    MedspaProvidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MedspaProvidersQuery,
    MedspaProvidersQueryVariables
  >(MedspaProvidersDocument, options);
}
export function useMedspaProvidersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MedspaProvidersQuery,
        MedspaProvidersQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MedspaProvidersQuery,
    MedspaProvidersQueryVariables
  >(MedspaProvidersDocument, options);
}
export type MedspaProvidersQueryHookResult = ReturnType<
  typeof useMedspaProvidersQuery
>;
export type MedspaProvidersLazyQueryHookResult = ReturnType<
  typeof useMedspaProvidersLazyQuery
>;
export type MedspaProvidersSuspenseQueryHookResult = ReturnType<
  typeof useMedspaProvidersSuspenseQuery
>;
export type MedspaProvidersQueryResult = Apollo.QueryResult<
  MedspaProvidersQuery,
  MedspaProvidersQueryVariables
>;
export const MedspasProvidersDocument = gql`
  query MedspasProviders($medspaIds: [bigint!]) {
    medspa(where: { id: { _in: $medspaIds } }, orderBy: { name: ASC }) {
      id
      name
      userMedspas(
        where: {
          active: { _eq: true }
          _or: [
            { featurePermissions: { codename: { _eq: "is_provider" } } }
            { role: { _eq: "provider" } }
          ]
        }
        orderBy: { user: { firstName: ASC } }
      ) {
        id
        user {
          id
          fullName
        }
      }
    }
  }
`;

/**
 * __useMedspasProvidersQuery__
 *
 * To run a query within a React component, call `useMedspasProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedspasProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedspasProvidersQuery({
 *   variables: {
 *      medspaIds: // value for 'medspaIds'
 *   },
 * });
 */
export function useMedspasProvidersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MedspasProvidersQuery,
    MedspasProvidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MedspasProvidersQuery, MedspasProvidersQueryVariables>(
    MedspasProvidersDocument,
    options
  );
}
export function useMedspasProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MedspasProvidersQuery,
    MedspasProvidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MedspasProvidersQuery,
    MedspasProvidersQueryVariables
  >(MedspasProvidersDocument, options);
}
export function useMedspasProvidersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MedspasProvidersQuery,
        MedspasProvidersQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MedspasProvidersQuery,
    MedspasProvidersQueryVariables
  >(MedspasProvidersDocument, options);
}
export type MedspasProvidersQueryHookResult = ReturnType<
  typeof useMedspasProvidersQuery
>;
export type MedspasProvidersLazyQueryHookResult = ReturnType<
  typeof useMedspasProvidersLazyQuery
>;
export type MedspasProvidersSuspenseQueryHookResult = ReturnType<
  typeof useMedspasProvidersSuspenseQuery
>;
export type MedspasProvidersQueryResult = Apollo.QueryResult<
  MedspasProvidersQuery,
  MedspasProvidersQueryVariables
>;

import { useEffect, useMemo, useState } from "react";
import { useAddTelehealthVisitDetailsMutation } from "@/graphql/mutations/telehealth/addTelehealthVisitDetails.graphql.types";
import { useUpdateTelehealthVisitDetailsMutation } from "@/graphql/mutations/telehealth/updateTelehealthVisitDetails.graphql.types";
import { TelehealthType } from "@/types";
import { getTelehealthTypeSchema } from "@/utils/telehealth";

export type TelehealthDetails = {
  visitType: TelehealthType;
  videoLink?: string;
  visitDetails?: string;
};

export default function useTelehealthDetails(
  visitId: string,
  initData?: TelehealthDetails
) {
  const [telehealthType, setTelehealthType] = useState<TelehealthType>(
    TelehealthType.VIDEO
  );
  const [telehealthVideoLink, setTelehealthVideoLink] = useState<string>("");
  const [telehealthAdditionalDetails, setTelehealthAdditionalDetails] =
    useState<string>("");

  const [isTelehealthVisitDetailsValid, setIsTelehealthVisitDetailsValid] =
    useState<boolean>(false); // managed by form component, has to be accessible by top level component

  const [addTelehealthDetails] = useAddTelehealthVisitDetailsMutation();
  const [editTelehealthDetails] = useUpdateTelehealthVisitDetailsMutation();

  const getMutationVariables = useMemo(() => {
    // On the backend side empty fields are stored as null
    // On the frontend side we want to use empty strings to avoid uncontrolled input component warning
    const variables = {
      visitId,
      visitType: getTelehealthTypeSchema(telehealthType),
      videoLink:
        telehealthType === TelehealthType.PHONE
          ? undefined
          : telehealthVideoLink || null,
      visitDetails: telehealthAdditionalDetails || null,
    };

    return {
      variables,
      update: (cache) => {
        cache.modify({
          id: cache.identify({ __typename: "visit", id: visitId }),
          fields: {
            telehealthVisitDetails: () => ({
              ...variables,
              visitType: variables.visitType.toLocaleLowerCase(),
            }),
          },
        });
      },
    };
  }, [
    visitId,
    telehealthType,
    telehealthVideoLink,
    telehealthAdditionalDetails,
  ]);

  const handleAddTelehealthDetails = () => {
    return addTelehealthDetails(getMutationVariables);
  };

  const handleEditTelehealthDetails = () => {
    return editTelehealthDetails(getMutationVariables);
  };

  const setDefaultValues = () => {
    setTelehealthType(TelehealthType.VIDEO);
    setTelehealthVideoLink("");
    setTelehealthAdditionalDetails("");
  };

  useEffect(() => {
    if (initData) {
      setTelehealthType(initData.visitType);
      setTelehealthVideoLink(initData.videoLink || "");
      setTelehealthAdditionalDetails(initData.visitDetails || "");
    } else {
      setDefaultValues();
    }
  }, [initData]);

  return {
    telehealthType,
    setTelehealthType,
    telehealthVideoLink,
    setTelehealthVideoLink,
    telehealthAdditionalDetails,
    setTelehealthAdditionalDetails,
    isTelehealthVisitDetailsValid,
    setIsTelehealthVisitDetailsValid,
    handleAddTelehealthDetails,
    handleEditTelehealthDetails,
    resetValues: setDefaultValues,
  };
}

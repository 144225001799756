// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type UpdateClientPaymentPlansInterestMutationVariables = Types.Exact<{
  clientId: Types.Scalars["bigint"]["input"];
  interestedInPaymentPlans: Types.Scalars["Boolean"]["input"];
}>;

export type UpdateClientPaymentPlansInterestMutation = {
  __typename?: "mutation_root";
  updateClientByPk?: {
    __typename?: "client";
    id: string;
    interestedInPaymentPlans: boolean;
  } | null;
};

export const UpdateClientPaymentPlansInterestDocument = gql`
  mutation UpdateClientPaymentPlansInterest(
    $clientId: bigint!
    $interestedInPaymentPlans: Boolean!
  ) {
    updateClientByPk(
      pkColumns: { id: $clientId }
      _set: { interestedInPaymentPlans: $interestedInPaymentPlans }
    ) {
      id
      interestedInPaymentPlans
    }
  }
`;
export type UpdateClientPaymentPlansInterestMutationFn =
  Apollo.MutationFunction<
    UpdateClientPaymentPlansInterestMutation,
    UpdateClientPaymentPlansInterestMutationVariables
  >;

/**
 * __useUpdateClientPaymentPlansInterestMutation__
 *
 * To run a mutation, you first call `useUpdateClientPaymentPlansInterestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientPaymentPlansInterestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientPaymentPlansInterestMutation, { data, loading, error }] = useUpdateClientPaymentPlansInterestMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      interestedInPaymentPlans: // value for 'interestedInPaymentPlans'
 *   },
 * });
 */
export function useUpdateClientPaymentPlansInterestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientPaymentPlansInterestMutation,
    UpdateClientPaymentPlansInterestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientPaymentPlansInterestMutation,
    UpdateClientPaymentPlansInterestMutationVariables
  >(UpdateClientPaymentPlansInterestDocument, options);
}
export type UpdateClientPaymentPlansInterestMutationHookResult = ReturnType<
  typeof useUpdateClientPaymentPlansInterestMutation
>;
export type UpdateClientPaymentPlansInterestMutationResult =
  Apollo.MutationResult<UpdateClientPaymentPlansInterestMutation>;
export type UpdateClientPaymentPlansInterestMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateClientPaymentPlansInterestMutation,
    UpdateClientPaymentPlansInterestMutationVariables
  >;

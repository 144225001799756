import { Button, ButtonProps, SxProps, Theme, useTheme } from "@mui/material";
import { ReactNode } from "react";
import { GREY } from "@/config/mui/colorPalette";
import { PlusCircleIcon } from "../icons";

type Props = ButtonProps & {
  id?: string;
  text: string;
  onClick?: () => void;
  link?: string;
  withIcon?: boolean;
  disabled?: boolean;
  small?: boolean;
  sx?: SxProps<Theme>;
  customIcon?: ReactNode;
  children?: ReactNode;
  component?: "button" | "label";
};

export default function OutlinedButton({
  id,
  text,
  onClick,
  link,
  withIcon = true,
  disabled = false,
  small = false,
  sx = {},
  customIcon,
  children,
  component = "button",
  ...props
}: Props) {
  const theme = useTheme();
  return (
    <Button
      id={id}
      component={component}
      variant="outlined"
      fullWidth={!small}
      disabled={disabled}
      size={small ? "small" : "large"}
      startIcon={
        customIcon ||
        (withIcon && (
          <PlusCircleIcon
            color={disabled ? GREY[40] : theme.palette.primary.main}
          />
        ))
      }
      sx={{
        mb: 2,
        ...(small && { px: 2 }),
        ...sx,
      }}
      onClick={link ? undefined : onClick}
      href={link}
      {...props}
    >
      {text}
      {children}
    </Button>
  );
}

import { differenceInDays, parseISO } from "date-fns";
import { ClientCherryApplicationRecordStatus } from "@/types";

export enum CherryApplicationStatus {
  NO_RECORD = "no_record",
  EXPIRED = "expired",
  EXPIRING_SOON = "expiring_soon",
  IN_PROGRESS = "in_progress",
  APPROVED = "approved",
  NOT_APPROVED = "not_approved",
}

export const CHERRY_STATUS_TEXT = {
  [CherryApplicationStatus.NO_RECORD]: "No application record",
  [CherryApplicationStatus.EXPIRED]: "Expired",
  [CherryApplicationStatus.EXPIRING_SOON]: "Expiring Soon",
  [CherryApplicationStatus.IN_PROGRESS]: "In Progress",
  [CherryApplicationStatus.APPROVED]: "Approved",
  [CherryApplicationStatus.NOT_APPROVED]: "Not approved",
} as const satisfies Record<CherryApplicationStatus, string>;

export function getCherryApplicationStatus(
  status: ClientCherryApplicationRecordStatus | undefined,
  expiresAt?: string
) {
  switch (status) {
    case undefined:
      return CherryApplicationStatus.NO_RECORD;
    case ClientCherryApplicationRecordStatus.APPROVED:
      if (expiresAt && differenceInDays(parseISO(expiresAt), new Date()) < 7) {
        return CherryApplicationStatus.EXPIRING_SOON;
      }
      return CherryApplicationStatus.APPROVED;
    case ClientCherryApplicationRecordStatus.NOT_APPROVED:
      return CherryApplicationStatus.NOT_APPROVED;
    case ClientCherryApplicationRecordStatus.PENDING:
      return CherryApplicationStatus.IN_PROGRESS;
    case ClientCherryApplicationRecordStatus.EXPIRED:
      return CherryApplicationStatus.EXPIRED;
    default:
      return status satisfies never;
  }
}

import { useRouter } from "next/router";
import toast from "react-hot-toast";
import { useUser } from "@/auth/useUser";
import { useConfirm } from "@/components/providers/confirmProvider";
import { useCreateInvoiceMutation } from "@/graphql/mutations/createInvoice.graphql.types";
import { useSetVisitStatusMutation } from "@/graphql/mutations/setVisitStatus.graphql.types";
import { VisitStatus } from "@/types";
import useErrorLogger from "@/utils/useErrorLogger";
import { useLastSeenClient } from "../serviceFlow/useLastSeenClient";

const CONFIRM_WITHOUT_REBOOKING_MODAL_CONFIG = {
  title: "Checkout without rebooking?",
  discardButtonText: "No, rebook now",
  confirmButtonText: "Yes, checkout",
  description:
    "This client is not rebooked. We recommend rebooking the client before checkout. Do you want to check out this client without rebooking?",
};

export default function useCompleteVisit({
  id,
  status,
  invoice,
  followUpVisitBooked,
  onRebookClick,
}: {
  id: string;
  status: string; // current visit status
  invoice?: { id?: string };
  followUpVisitBooked?: boolean;
  onRebookClick?: () => void;
}) {
  const logError = useErrorLogger();
  const { medspa } = useUser();
  const { push } = useRouter();
  const [setVisitStatus] = useSetVisitStatusMutation();
  const [createInvoiceMutation] = useCreateInvoiceMutation();
  const { setLastSeenClientId } = useLastSeenClient();
  const { getConfirm } = useConfirm();

  const gotToInvoicePage = (invoiceId?: string) => {
    setLastSeenClientId(null); // so that we come back to visit - not client
    return push(`/${medspa}/invoices/${invoiceId}`);
  };

  return async () => {
    try {
      let invoiceId = invoice?.id;

      if (!invoice) {
        const { data } = await createInvoiceMutation({
          variables: {
            visitId: id,
          },
        });
        invoiceId = data?.createInvoice?.invoice?.id;
      }

      if (status !== VisitStatus.COMPLETED) {
        if (followUpVisitBooked !== undefined && !followUpVisitBooked) {
          const shouldContinueWithoutRebooking = await getConfirm(
            CONFIRM_WITHOUT_REBOOKING_MODAL_CONFIG
          );

          if (!shouldContinueWithoutRebooking) {
            onRebookClick?.();
            return;
          }
        }

        await setVisitStatus({
          variables: { id, status: VisitStatus.COMPLETED },
        });
      }

      gotToInvoicePage(invoiceId);
    } catch (errors) {
      toast.error(
        "We couldn't mark the visit as complete. Please try again later."
      );
      logError(`Exception while marking visit as complete: ${errors}`);
      return;
    }
  };
}

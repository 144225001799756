// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type ClientBasicInfoQueryVariables = Types.Exact<{
  clientId: Types.Scalars["bigint"]["input"];
}>;

export type ClientBasicInfoQuery = {
  __typename?: "query_root";
  clientByPk?: {
    __typename?: "client";
    id: string;
    note: string;
    email: string;
    phone: string;
    gender?: string | null;
    medspaId: string;
    firstName: string;
    lastName: string;
    birthdate?: string | null;
    knowsMedspaFrom?: string | null;
    lastSentFormsAt?: string | null;
    twilioOptedOutOfSms: boolean;
    twilioConversationId: string;
    hadAdverseReaction: boolean;
    address?: {
      __typename?: "address";
      city: string;
      state: string;
      zipCode: string;
      addressLine1: string;
      addressLine2: string;
    } | null;
    pendingGfeReview?: Array<{
      __typename?: "GfeReviewRequest";
      id: string;
      asyncReviewedAt?: string | null;
    }> | null;
    lastGfeReview?: Array<{
      __typename?: "GfeReviewRequest";
      id: string;
    }> | null;
  } | null;
};

export const ClientBasicInfoDocument = gql`
  query ClientBasicInfo($clientId: bigint!) {
    clientByPk(id: $clientId) {
      id
      note
      email
      phone
      gender
      medspaId
      firstName
      lastName
      birthdate
      knowsMedspaFrom
      lastSentFormsAt
      twilioOptedOutOfSms
      twilioConversationId
      hadAdverseReaction
      address {
        city
        state
        zipCode
        addressLine1
        addressLine2
      }
      pendingGfeReview {
        id
        asyncReviewedAt
      }
      lastGfeReview {
        id
      }
    }
  }
`;

/**
 * __useClientBasicInfoQuery__
 *
 * To run a query within a React component, call `useClientBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientBasicInfoQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientBasicInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientBasicInfoQuery,
    ClientBasicInfoQueryVariables
  > &
    (
      | { variables: ClientBasicInfoQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientBasicInfoQuery, ClientBasicInfoQueryVariables>(
    ClientBasicInfoDocument,
    options
  );
}
export function useClientBasicInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientBasicInfoQuery,
    ClientBasicInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientBasicInfoQuery,
    ClientBasicInfoQueryVariables
  >(ClientBasicInfoDocument, options);
}
export function useClientBasicInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ClientBasicInfoQuery,
        ClientBasicInfoQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClientBasicInfoQuery,
    ClientBasicInfoQueryVariables
  >(ClientBasicInfoDocument, options);
}
export type ClientBasicInfoQueryHookResult = ReturnType<
  typeof useClientBasicInfoQuery
>;
export type ClientBasicInfoLazyQueryHookResult = ReturnType<
  typeof useClientBasicInfoLazyQuery
>;
export type ClientBasicInfoSuspenseQueryHookResult = ReturnType<
  typeof useClientBasicInfoSuspenseQuery
>;
export type ClientBasicInfoQueryResult = Apollo.QueryResult<
  ClientBasicInfoQuery,
  ClientBasicInfoQueryVariables
>;

import { Box, Button, Stack, Typography } from "@mui/material";
import { UserPlusIcon } from "@/components/common/icons";
import { GREY, VIOLET, WHITE } from "@/config/mui/colorPalette";
import NextLink from "@/config/mui/nextLink";
import { useMedspaId } from "@/hooks/common/useMedspaId";

export default function ReferralCard() {
  const medspa = useMedspaId();
  return (
    <Box
      sx={{
        backgroundColor: WHITE,
        borderRadius: 3,
        border: `1px solid ${GREY[15]}`,
        p: 1.5,
        width: "100%",
      }}
    >
      <Stack spacing={1.5}>
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Box
            sx={{
              backgroundColor: VIOLET[20],
              borderRadius: 100,
              width: 40,
              height: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <UserPlusIcon size={20} color={VIOLET[90]} set="duotone" />
          </Box>
          <Typography variant="labelSmall" color="text.primary">
            Refer & Earn
          </Typography>
        </Stack>
        <Typography
          variant="paragraphSmall"
          color="text.secondary"
          sx={{ mb: 1 }}
        >
          Refer a provider to Moxie and earn{" "}
          <Typography
            variant="subtitleSmall"
            color="text.primary"
            component="span"
          >
            $500
          </Typography>{" "}
          at launch.
        </Typography>
        <Button
          component={NextLink}
          href={`/${medspa}/referral`}
          variant="contained"
          size="small"
          sx={{
            backgroundColor: VIOLET[40],
            color: VIOLET[90],
            "&:hover": {
              backgroundColor: VIOLET[60],
            },
          }}
        >
          Learn more
        </Button>
      </Stack>
    </Box>
  );
}

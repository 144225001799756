// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";
import { ComplianceTaskFieldsFragmentDoc } from "../../fragments/complianceHub/complianceTaskFields.graphql.types";

const defaultOptions = {} as const;
export type ComplianceTasksQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["BigInt"]["input"];
}>;

export type ComplianceTasksQuery = {
  __typename?: "query_root";
  medspaComplianceTasks?: {
    __typename?: "MedSpaComplianceTasksType";
    msoTasks?: Array<{
      __typename?: "ComplianceTaskType";
      id?: number | null;
      name?: string | null;
      status?: string | null;
      required?: boolean | null;
      complianceTaskTemplate?: {
        __typename?: "ComplianceTaskTemplateType";
        id?: number | null;
        canMarkNotRequired?: boolean | null;
      } | null;
    } | null> | null;
    pcTasks?: Array<{
      __typename?: "ComplianceTaskType";
      id?: number | null;
      name?: string | null;
      status?: string | null;
      required?: boolean | null;
      complianceTaskTemplate?: {
        __typename?: "ComplianceTaskTemplateType";
        id?: number | null;
        canMarkNotRequired?: boolean | null;
      } | null;
    } | null> | null;
    providerTasks?: {
      __typename?: "ProviderTasksType";
      complianceTaskTemplates?: Array<{
        __typename?: "ComplianceTaskTemplateType";
        id?: number | null;
        name?: string | null;
        expires?: boolean | null;
        canMarkNotRequired?: boolean | null;
      } | null> | null;
      providers?: Array<{
        __typename?: "ProviderComplianceTaskType";
        providerId?: number | null;
        providerFullName?: string | null;
        complianceTasks?: Array<{
          __typename?: "ComplianceTaskType";
          id?: number | null;
          name?: string | null;
          status?: string | null;
          required?: boolean | null;
          complianceTaskTemplate?: {
            __typename?: "ComplianceTaskTemplateType";
            id?: number | null;
          } | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ComplianceMsoTasksQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["bigint"]["input"];
}>;

export type ComplianceMsoTasksQuery = {
  __typename?: "query_root";
  complianceTask: Array<{
    __typename?: "ComplianceTask";
    id: string;
    name: string;
    expiresAt?: string | null;
    required: boolean;
    status: string;
    providerId?: string | null;
    complianceTaskTemplate: {
      __typename?: "ComplianceTaskTemplate";
      id: string;
      canMarkNotRequired: boolean;
      expires: boolean;
      isMsoTask: boolean;
      name: string;
      requiresDocument: boolean;
    };
    provider?: {
      __typename?: "userMedspa";
      id: string;
      user: { __typename?: "user"; fullName?: string | null; id: string };
    } | null;
    complianceTaskDocuments: Array<{
      __typename?: "ComplianceTaskDocument";
      id: string;
      pdfFilePath?: string | null;
      status: string;
      archivedAt?: string | null;
      archivedById?: string | null;
      boldsignDocumentId: string;
    }>;
  }>;
};

export type CompliancePcTasksByMedspaIdQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["bigint"]["input"];
}>;

export type CompliancePcTasksByMedspaIdQuery = {
  __typename?: "query_root";
  complianceTask: Array<{
    __typename?: "ComplianceTask";
    id: string;
    name: string;
    expiresAt?: string | null;
    required: boolean;
    status: string;
    providerId?: string | null;
    complianceTaskTemplate: {
      __typename?: "ComplianceTaskTemplate";
      id: string;
      canMarkNotRequired: boolean;
      expires: boolean;
      isMsoTask: boolean;
      name: string;
      requiresDocument: boolean;
    };
    provider?: {
      __typename?: "userMedspa";
      id: string;
      user: { __typename?: "user"; fullName?: string | null; id: string };
    } | null;
    complianceTaskDocuments: Array<{
      __typename?: "ComplianceTaskDocument";
      id: string;
      pdfFilePath?: string | null;
      status: string;
      archivedAt?: string | null;
      archivedById?: string | null;
      boldsignDocumentId: string;
    }>;
  }>;
};

export type CompliancePcTasksByPcIdQueryVariables = Types.Exact<{
  pcId: Types.Scalars["bigint"]["input"];
}>;

export type CompliancePcTasksByPcIdQuery = {
  __typename?: "query_root";
  complianceTask: Array<{
    __typename?: "ComplianceTask";
    id: string;
    name: string;
    expiresAt?: string | null;
    required: boolean;
    status: string;
    providerId?: string | null;
    complianceTaskTemplate: {
      __typename?: "ComplianceTaskTemplate";
      id: string;
      canMarkNotRequired: boolean;
      expires: boolean;
      isMsoTask: boolean;
      name: string;
      requiresDocument: boolean;
    };
    provider?: {
      __typename?: "userMedspa";
      id: string;
      user: { __typename?: "user"; fullName?: string | null; id: string };
    } | null;
    complianceTaskDocuments: Array<{
      __typename?: "ComplianceTaskDocument";
      id: string;
      pdfFilePath?: string | null;
      status: string;
      archivedAt?: string | null;
      archivedById?: string | null;
      boldsignDocumentId: string;
    }>;
  }>;
};

export const ComplianceTasksDocument = gql`
  query ComplianceTasks($medspaId: BigInt!) {
    medspaComplianceTasks(medspaId: $medspaId) {
      msoTasks {
        id
        name
        status
        required
        complianceTaskTemplate {
          id
          canMarkNotRequired
        }
      }
      pcTasks {
        id
        name
        status
        required
        complianceTaskTemplate {
          id
          canMarkNotRequired
        }
      }
      providerTasks {
        complianceTaskTemplates {
          id
          name
          expires
          canMarkNotRequired
        }
        providers {
          providerId
          providerFullName
          complianceTasks {
            id
            name
            status
            required
            complianceTaskTemplate {
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useComplianceTasksQuery__
 *
 * To run a query within a React component, call `useComplianceTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useComplianceTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComplianceTasksQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useComplianceTasksQuery(
  baseOptions: Apollo.QueryHookOptions<
    ComplianceTasksQuery,
    ComplianceTasksQueryVariables
  > &
    (
      | { variables: ComplianceTasksQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ComplianceTasksQuery, ComplianceTasksQueryVariables>(
    ComplianceTasksDocument,
    options
  );
}
export function useComplianceTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ComplianceTasksQuery,
    ComplianceTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ComplianceTasksQuery,
    ComplianceTasksQueryVariables
  >(ComplianceTasksDocument, options);
}
export function useComplianceTasksSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ComplianceTasksQuery,
        ComplianceTasksQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ComplianceTasksQuery,
    ComplianceTasksQueryVariables
  >(ComplianceTasksDocument, options);
}
export type ComplianceTasksQueryHookResult = ReturnType<
  typeof useComplianceTasksQuery
>;
export type ComplianceTasksLazyQueryHookResult = ReturnType<
  typeof useComplianceTasksLazyQuery
>;
export type ComplianceTasksSuspenseQueryHookResult = ReturnType<
  typeof useComplianceTasksSuspenseQuery
>;
export type ComplianceTasksQueryResult = Apollo.QueryResult<
  ComplianceTasksQuery,
  ComplianceTasksQueryVariables
>;
export const ComplianceMsoTasksDocument = gql`
  query ComplianceMsoTasks($medspaId: bigint!) {
    complianceTask(
      where: {
        _and: {
          medspaId: { _eq: $medspaId }
          complianceTaskTemplate: { isMsoTask: { _eq: false } }
          providerId: { _isNull: true }
        }
      }
    ) {
      ...ComplianceTaskFields
    }
  }
  ${ComplianceTaskFieldsFragmentDoc}
`;

/**
 * __useComplianceMsoTasksQuery__
 *
 * To run a query within a React component, call `useComplianceMsoTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useComplianceMsoTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComplianceMsoTasksQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useComplianceMsoTasksQuery(
  baseOptions: Apollo.QueryHookOptions<
    ComplianceMsoTasksQuery,
    ComplianceMsoTasksQueryVariables
  > &
    (
      | { variables: ComplianceMsoTasksQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ComplianceMsoTasksQuery,
    ComplianceMsoTasksQueryVariables
  >(ComplianceMsoTasksDocument, options);
}
export function useComplianceMsoTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ComplianceMsoTasksQuery,
    ComplianceMsoTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ComplianceMsoTasksQuery,
    ComplianceMsoTasksQueryVariables
  >(ComplianceMsoTasksDocument, options);
}
export function useComplianceMsoTasksSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ComplianceMsoTasksQuery,
        ComplianceMsoTasksQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ComplianceMsoTasksQuery,
    ComplianceMsoTasksQueryVariables
  >(ComplianceMsoTasksDocument, options);
}
export type ComplianceMsoTasksQueryHookResult = ReturnType<
  typeof useComplianceMsoTasksQuery
>;
export type ComplianceMsoTasksLazyQueryHookResult = ReturnType<
  typeof useComplianceMsoTasksLazyQuery
>;
export type ComplianceMsoTasksSuspenseQueryHookResult = ReturnType<
  typeof useComplianceMsoTasksSuspenseQuery
>;
export type ComplianceMsoTasksQueryResult = Apollo.QueryResult<
  ComplianceMsoTasksQuery,
  ComplianceMsoTasksQueryVariables
>;
export const CompliancePcTasksByMedspaIdDocument = gql`
  query CompliancePcTasksByMedspaId($medspaId: bigint!) {
    complianceTask(
      where: {
        _and: {
          medspaId: { _eq: $medspaId }
          complianceTaskTemplate: { isMsoTask: { _eq: true } }
          providerId: { _isNull: true }
        }
      }
    ) {
      ...ComplianceTaskFields
    }
  }
  ${ComplianceTaskFieldsFragmentDoc}
`;

/**
 * __useCompliancePcTasksByMedspaIdQuery__
 *
 * To run a query within a React component, call `useCompliancePcTasksByMedspaIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompliancePcTasksByMedspaIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompliancePcTasksByMedspaIdQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useCompliancePcTasksByMedspaIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompliancePcTasksByMedspaIdQuery,
    CompliancePcTasksByMedspaIdQueryVariables
  > &
    (
      | { variables: CompliancePcTasksByMedspaIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompliancePcTasksByMedspaIdQuery,
    CompliancePcTasksByMedspaIdQueryVariables
  >(CompliancePcTasksByMedspaIdDocument, options);
}
export function useCompliancePcTasksByMedspaIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompliancePcTasksByMedspaIdQuery,
    CompliancePcTasksByMedspaIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompliancePcTasksByMedspaIdQuery,
    CompliancePcTasksByMedspaIdQueryVariables
  >(CompliancePcTasksByMedspaIdDocument, options);
}
export function useCompliancePcTasksByMedspaIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompliancePcTasksByMedspaIdQuery,
        CompliancePcTasksByMedspaIdQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompliancePcTasksByMedspaIdQuery,
    CompliancePcTasksByMedspaIdQueryVariables
  >(CompliancePcTasksByMedspaIdDocument, options);
}
export type CompliancePcTasksByMedspaIdQueryHookResult = ReturnType<
  typeof useCompliancePcTasksByMedspaIdQuery
>;
export type CompliancePcTasksByMedspaIdLazyQueryHookResult = ReturnType<
  typeof useCompliancePcTasksByMedspaIdLazyQuery
>;
export type CompliancePcTasksByMedspaIdSuspenseQueryHookResult = ReturnType<
  typeof useCompliancePcTasksByMedspaIdSuspenseQuery
>;
export type CompliancePcTasksByMedspaIdQueryResult = Apollo.QueryResult<
  CompliancePcTasksByMedspaIdQuery,
  CompliancePcTasksByMedspaIdQueryVariables
>;
export const CompliancePcTasksByPcIdDocument = gql`
  query CompliancePcTasksByPcId($pcId: bigint!) {
    complianceTask(
      where: {
        _and: {
          professionalCorporationId: { _eq: $pcId }
          complianceTaskTemplate: { isMsoTask: { _eq: true } }
          providerId: { _isNull: true }
        }
      }
    ) {
      ...ComplianceTaskFields
    }
  }
  ${ComplianceTaskFieldsFragmentDoc}
`;

/**
 * __useCompliancePcTasksByPcIdQuery__
 *
 * To run a query within a React component, call `useCompliancePcTasksByPcIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompliancePcTasksByPcIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompliancePcTasksByPcIdQuery({
 *   variables: {
 *      pcId: // value for 'pcId'
 *   },
 * });
 */
export function useCompliancePcTasksByPcIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompliancePcTasksByPcIdQuery,
    CompliancePcTasksByPcIdQueryVariables
  > &
    (
      | { variables: CompliancePcTasksByPcIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompliancePcTasksByPcIdQuery,
    CompliancePcTasksByPcIdQueryVariables
  >(CompliancePcTasksByPcIdDocument, options);
}
export function useCompliancePcTasksByPcIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompliancePcTasksByPcIdQuery,
    CompliancePcTasksByPcIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompliancePcTasksByPcIdQuery,
    CompliancePcTasksByPcIdQueryVariables
  >(CompliancePcTasksByPcIdDocument, options);
}
export function useCompliancePcTasksByPcIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompliancePcTasksByPcIdQuery,
        CompliancePcTasksByPcIdQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CompliancePcTasksByPcIdQuery,
    CompliancePcTasksByPcIdQueryVariables
  >(CompliancePcTasksByPcIdDocument, options);
}
export type CompliancePcTasksByPcIdQueryHookResult = ReturnType<
  typeof useCompliancePcTasksByPcIdQuery
>;
export type CompliancePcTasksByPcIdLazyQueryHookResult = ReturnType<
  typeof useCompliancePcTasksByPcIdLazyQuery
>;
export type CompliancePcTasksByPcIdSuspenseQueryHookResult = ReturnType<
  typeof useCompliancePcTasksByPcIdSuspenseQuery
>;
export type CompliancePcTasksByPcIdQueryResult = Apollo.QueryResult<
  CompliancePcTasksByPcIdQuery,
  CompliancePcTasksByPcIdQueryVariables
>;

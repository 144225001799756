// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type ClientsCardsOnFileQueryVariables = Types.Exact<{
  clientId: Types.Scalars["bigint"]["input"];
}>;

export type ClientsCardsOnFileQuery = {
  __typename?: "query_root";
  clientByPk?: {
    __typename?: "client";
    id: string;
    stripeData?: {
      __typename?: "ClientStripeType";
      id: string;
      stripeCardsList: Array<{
        __typename?: "MoxieCardType";
        id?: string | null;
        brand?: string | null;
        last4?: string | null;
        expMonth?: number | null;
        expYear?: number | null;
        isDefault?: boolean | null;
      } | null>;
    } | null;
  } | null;
};

export const ClientsCardsOnFileDocument = gql`
  query ClientsCardsOnFile($clientId: bigint!) {
    clientByPk(id: $clientId) {
      id
      stripeData {
        id
        stripeCardsList {
          id
          brand
          last4
          expMonth
          expYear
          isDefault
        }
      }
    }
  }
`;

/**
 * __useClientsCardsOnFileQuery__
 *
 * To run a query within a React component, call `useClientsCardsOnFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsCardsOnFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsCardsOnFileQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientsCardsOnFileQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientsCardsOnFileQuery,
    ClientsCardsOnFileQueryVariables
  > &
    (
      | { variables: ClientsCardsOnFileQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClientsCardsOnFileQuery,
    ClientsCardsOnFileQueryVariables
  >(ClientsCardsOnFileDocument, options);
}
export function useClientsCardsOnFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientsCardsOnFileQuery,
    ClientsCardsOnFileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientsCardsOnFileQuery,
    ClientsCardsOnFileQueryVariables
  >(ClientsCardsOnFileDocument, options);
}
export function useClientsCardsOnFileSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ClientsCardsOnFileQuery,
        ClientsCardsOnFileQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClientsCardsOnFileQuery,
    ClientsCardsOnFileQueryVariables
  >(ClientsCardsOnFileDocument, options);
}
export type ClientsCardsOnFileQueryHookResult = ReturnType<
  typeof useClientsCardsOnFileQuery
>;
export type ClientsCardsOnFileLazyQueryHookResult = ReturnType<
  typeof useClientsCardsOnFileLazyQuery
>;
export type ClientsCardsOnFileSuspenseQueryHookResult = ReturnType<
  typeof useClientsCardsOnFileSuspenseQuery
>;
export type ClientsCardsOnFileQueryResult = Apollo.QueryResult<
  ClientsCardsOnFileQuery,
  ClientsCardsOnFileQueryVariables
>;

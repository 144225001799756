import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  useTheme,
  RadioProps,
  CheckboxProps,
} from "@mui/material";
import { MouseEvent, ReactNode } from "react";
import { MoxieCheckbox } from "@/components/common/checkbox/moxieCheckbox";
import { MoxieRadio } from "@/components/common/radio/moxieRadio";

type ListItemControlProps = {
  control: ReactNode;
  children?: ReactNode;
  withDivider?: boolean;
  secondaryAction?: ReactNode;
};

function ListItemControl({
  control,
  secondaryAction,
  withDivider,
  children,
}: ListItemControlProps) {
  const theme = useTheme();

  return (
    <ListItem
      component="div"
      disablePadding
      divider={withDivider}
      secondaryAction={secondaryAction}
      sx={{
        borderColor: theme.palette.lightGray.main,
        ".MuiListItemSecondaryAction-root": { top: 0.5, transform: "none" },
      }}
    >
      {control}
      {children}
    </ListItem>
  );
}

type ListItemControlButtonProps = {
  label: ReactNode;
  secondaryLabel?: ReactNode;
  labelId: string;
  isDisabled?: boolean;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
  rightElement?: ReactNode;
};

type ListItemGenericControlButtonProps = ListItemControlButtonProps & {
  control?: ReactNode;
};

export function ListItemControlButton({
  control,
  labelId,
  label,
  secondaryLabel,
  isDisabled,
  onClick,
  rightElement,
}: ListItemGenericControlButtonProps) {
  return (
    <ListItemButton
      onClick={onClick}
      sx={{ p: 1.5, alignItems: "center" }}
      disabled={isDisabled}
    >
      {control && (
        <ListItemIcon sx={{ minWidth: "auto", alignSelf: "flex-start" }}>
          {control}
        </ListItemIcon>
      )}
      <ListItemText
        sx={{ my: 0 }}
        id={labelId}
        primary={label}
        secondary={secondaryLabel}
        slotProps={{ secondary: { component: "div" } }}
      />
      {rightElement}
    </ListItemButton>
  );
}

type ListItemRadioButtonProps = RadioProps & ListItemControlButtonProps;

export function ListItemRadioButton({
  id,
  label,
  secondaryLabel,
  onClick,
  labelId,
  rightElement,
  ...radioProps
}: ListItemRadioButtonProps) {
  return (
    <ListItemControlButton
      label={label}
      labelId={labelId}
      secondaryLabel={secondaryLabel}
      onClick={onClick}
      isDisabled={radioProps.disabled}
      rightElement={rightElement}
      control={
        <MoxieRadio
          id={id}
          value={id}
          edge="start"
          tabIndex={-1}
          inputProps={{ "aria-labelledby": labelId }}
          {...radioProps}
        />
      }
    />
  );
}

type ListItemCheckboxButtonProps = CheckboxProps & ListItemControlButtonProps;

export function ListItemCheckboxButton({
  label,
  secondaryLabel,
  onClick,
  labelId,
  ...checkboxProps
}: ListItemCheckboxButtonProps) {
  return (
    <ListItemControlButton
      label={label}
      labelId={labelId}
      secondaryLabel={secondaryLabel}
      onClick={onClick}
      isDisabled={checkboxProps.disabled}
      control={
        <MoxieCheckbox
          edge="start"
          tabIndex={-1}
          inputProps={{ "aria-labelledby": labelId }}
          disableRipple
          {...checkboxProps}
        />
      }
    />
  );
}

export default ListItemControl;

// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type OverdueChartsPerProviderSubscriptionVariables = Types.Exact<{
  id: Types.Scalars["bigint"]["input"];
}>;

export type OverdueChartsPerProviderSubscription = {
  __typename?: "subscription_root";
  visitAggregate: {
    __typename?: "visitAggregate";
    aggregate?: { __typename?: "visitAggregateFields"; count: number } | null;
  };
};

export const OverdueChartsPerProviderDocument = gql`
  subscription OverdueChartsPerProvider($id: bigint!) {
    visitAggregate(
      where: {
        providerId: { _eq: $id }
        reviewStatus: { _eq: "overdue" }
        status: { _eq: "completed" }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useOverdueChartsPerProviderSubscription__
 *
 * To run a query within a React component, call `useOverdueChartsPerProviderSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOverdueChartsPerProviderSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverdueChartsPerProviderSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOverdueChartsPerProviderSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OverdueChartsPerProviderSubscription,
    OverdueChartsPerProviderSubscriptionVariables
  > &
    (
      | {
          variables: OverdueChartsPerProviderSubscriptionVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OverdueChartsPerProviderSubscription,
    OverdueChartsPerProviderSubscriptionVariables
  >(OverdueChartsPerProviderDocument, options);
}
export type OverdueChartsPerProviderSubscriptionHookResult = ReturnType<
  typeof useOverdueChartsPerProviderSubscription
>;
export type OverdueChartsPerProviderSubscriptionResult =
  Apollo.SubscriptionResult<OverdueChartsPerProviderSubscription>;

// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";
import { LastChartSignatureFragmentDoc } from "../../fragments/visit/lastChartSignature.graphql.types";

const defaultOptions = {} as const;
export type VisitChartSignatureDetailsQueryVariables = Types.Exact<{
  visitId: Types.Scalars["bigint"]["input"];
}>;

export type VisitChartSignatureDetailsQuery = {
  __typename?: "query_root";
  visitByPk?: {
    __typename?: "visit";
    id: string;
    startTime: string;
    client: { __typename?: "client"; fullName?: string | null };
    appointment?: {
      __typename?: "SchedulingAppointment";
      id: string;
      startTime?: string | null;
      endTime?: string | null;
    } | null;
    medspa: { __typename?: "medspa"; id: string; timezone: string };
    lastSignature: Array<{
      __typename?: "VisitsChartsignature";
      id: string;
      created: string;
      additionalInfo?: {
        __typename?: "ChartSignatureType";
        id: string;
        signatureUrl?: string | null;
      } | null;
      userMedspa?: {
        __typename?: "userMedspa";
        id: string;
        user: { __typename?: "user"; id: string; fullName?: string | null };
      } | null;
    }>;
  } | null;
};

export type VisitChartBaseDataQueryVariables = Types.Exact<{
  visitId: Types.Scalars["bigint"]["input"];
}>;

export type VisitChartBaseDataQuery = {
  __typename?: "query_root";
  visitByPk?: {
    __typename?: "visit";
    id: string;
    lastSignature: Array<{ __typename?: "VisitsChartsignature"; id: string }>;
  } | null;
};

export const VisitChartSignatureDetailsDocument = gql`
  query VisitChartSignatureDetails($visitId: bigint!) {
    visitByPk(id: $visitId) {
      id
      startTime
      client {
        fullName
      }
      appointment {
        id
        startTime
        endTime
      }
      medspa {
        id
        timezone
      }
      lastSignature: chartSignatures(where: { deletedAt: { _isNull: true } }) {
        id
        created
        additionalInfo {
          id
          signatureUrl
        }
        userMedspa {
          id
          user {
            id
            fullName
          }
        }
      }
    }
  }
`;

/**
 * __useVisitChartSignatureDetailsQuery__
 *
 * To run a query within a React component, call `useVisitChartSignatureDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitChartSignatureDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitChartSignatureDetailsQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useVisitChartSignatureDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    VisitChartSignatureDetailsQuery,
    VisitChartSignatureDetailsQueryVariables
  > &
    (
      | { variables: VisitChartSignatureDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VisitChartSignatureDetailsQuery,
    VisitChartSignatureDetailsQueryVariables
  >(VisitChartSignatureDetailsDocument, options);
}
export function useVisitChartSignatureDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VisitChartSignatureDetailsQuery,
    VisitChartSignatureDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VisitChartSignatureDetailsQuery,
    VisitChartSignatureDetailsQueryVariables
  >(VisitChartSignatureDetailsDocument, options);
}
export function useVisitChartSignatureDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VisitChartSignatureDetailsQuery,
        VisitChartSignatureDetailsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    VisitChartSignatureDetailsQuery,
    VisitChartSignatureDetailsQueryVariables
  >(VisitChartSignatureDetailsDocument, options);
}
export type VisitChartSignatureDetailsQueryHookResult = ReturnType<
  typeof useVisitChartSignatureDetailsQuery
>;
export type VisitChartSignatureDetailsLazyQueryHookResult = ReturnType<
  typeof useVisitChartSignatureDetailsLazyQuery
>;
export type VisitChartSignatureDetailsSuspenseQueryHookResult = ReturnType<
  typeof useVisitChartSignatureDetailsSuspenseQuery
>;
export type VisitChartSignatureDetailsQueryResult = Apollo.QueryResult<
  VisitChartSignatureDetailsQuery,
  VisitChartSignatureDetailsQueryVariables
>;
export const VisitChartBaseDataDocument = gql`
  query VisitChartBaseData($visitId: bigint!) {
    visitByPk(id: $visitId) {
      id
      ...LastChartSignature
    }
  }
  ${LastChartSignatureFragmentDoc}
`;

/**
 * __useVisitChartBaseDataQuery__
 *
 * To run a query within a React component, call `useVisitChartBaseDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitChartBaseDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitChartBaseDataQuery({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useVisitChartBaseDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    VisitChartBaseDataQuery,
    VisitChartBaseDataQueryVariables
  > &
    (
      | { variables: VisitChartBaseDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VisitChartBaseDataQuery,
    VisitChartBaseDataQueryVariables
  >(VisitChartBaseDataDocument, options);
}
export function useVisitChartBaseDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VisitChartBaseDataQuery,
    VisitChartBaseDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VisitChartBaseDataQuery,
    VisitChartBaseDataQueryVariables
  >(VisitChartBaseDataDocument, options);
}
export function useVisitChartBaseDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VisitChartBaseDataQuery,
        VisitChartBaseDataQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    VisitChartBaseDataQuery,
    VisitChartBaseDataQueryVariables
  >(VisitChartBaseDataDocument, options);
}
export type VisitChartBaseDataQueryHookResult = ReturnType<
  typeof useVisitChartBaseDataQuery
>;
export type VisitChartBaseDataLazyQueryHookResult = ReturnType<
  typeof useVisitChartBaseDataLazyQuery
>;
export type VisitChartBaseDataSuspenseQueryHookResult = ReturnType<
  typeof useVisitChartBaseDataSuspenseQuery
>;
export type VisitChartBaseDataQueryResult = Apollo.QueryResult<
  VisitChartBaseDataQuery,
  VisitChartBaseDataQueryVariables
>;
